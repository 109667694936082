import abraGif from "../assets/abra.gif";
import abraPng from "../assets/abra.png";
import abraBox from "../assets/abra-box-narrow.png";
import chanseyGif from "../assets/chansey.gif";
import chanseyPng from "../assets/chansey.png";
import chanseyBox from "../assets/chansey-box-narrow.png";
import charizardGif from "../assets/charizard.gif";
import charizardPng from "../assets/charizard.png";
import charizardBox from "../assets/charizard-box-narrow.png";
import electrodeGif from "../assets/electrode.gif";
import electrodePng from "../assets/electrode.png";
import electrodeBox from "../assets/electrode-box-narrow.png";
import kangaskhanGif from "../assets/kangaskhan.gif";
import kangaskhanPng from "../assets/kangaskhan.png";
import kangaskhanBox from "../assets/kangaskhan-box-narrow.png";
import laprasGif from "../assets/lapras.gif";
import laprasPng from "../assets/lapras.png";
import laprasBox from "../assets/lapras-box-narrow.png";
import pikachuGif from "../assets/pikachu.gif";
import pikachuPng from "../assets/pikachu.png";
import pikachuBox from "../assets/pikachu-box-narrow.png";
import {
  IPokemon,
  PokemonSpecies,
  PokemonType,
  PokemonVariant,
} from "../pokemon.types";

export const ABRA: IPokemon = {
  species: PokemonSpecies.ABRA,
  type: [PokemonType.PSYCHIC],
  category: "Psi",
  pokedex: {
    number: 63,
    entry:
      "Using its ability to read minds, it will sense impending data and TELEPORT to safety.",
  },
  height: { metres: 0.9, feet: 2, inches: 11 },
  weight: { kg: 19.5, lbs: 43 },
  src: { png: abraPng, gif: abraGif, box: abraBox },
  variants: [PokemonVariant.ABRA_FORESIGHT, PokemonVariant.ABRA_TELEPORT],
};

export const CHANSEY: IPokemon = {
  species: PokemonSpecies.CHANSEY,
  type: [PokemonType.NORMAL],
  category: "Egg",
  pokedex: {
    number: 113,
    entry:
      "A rare and elusive POKÉMON that is said to bring happiness to those who manage to catch one.",
  },
  height: { metres: 1.1, feet: 3, inches: 7 },
  weight: { kg: 34.6, lbs: 76.3 },
  src: { png: chanseyPng, gif: chanseyGif, box: chanseyBox },
};

export const CHARIZARD: IPokemon = {
  species: PokemonSpecies.CHARIZARD,
  type: [PokemonType.FIRE, PokemonType.FLYING],
  category: "Flame",
  pokedex: {
    number: 6,
    entry:
      "Spits fire that is hot enough to melt boulders. Known to cause forest fires unintentionally.",
  },
  height: { metres: 1.7, feet: 5, inches: 7 },
  weight: { kg: 90.5, lbs: 199.5 },
  src: { png: charizardPng, gif: charizardGif, box: charizardBox },
  variants: [PokemonVariant.CHARIZARD_FIRE_BLAST, PokemonVariant.CHARIZARD_FLY],
};

export const ELECTRODE: IPokemon = {
  species: PokemonSpecies.ELECTRODE,
  type: [PokemonType.ELECTRIC],
  category: "Ball",
  pokedex: {
    number: 101,
    entry:
      "It stores electric energy under very high pressure. It often explodes with little or no provocation.",
  },
  height: { metres: 1.2, feet: 3, inches: 11 },
  weight: { kg: 66.6, lbs: 146.8 },
  src: { png: electrodePng, gif: electrodeGif, box: electrodeBox },
};

export const KANGASKHAN: IPokemon = {
  species: PokemonSpecies.KANGASKHAN,
  type: [PokemonType.NORMAL],
  category: "Parent",
  pokedex: {
    number: 115,
    entry:
      "The infant rarely ventures out of its mother's protective pouch until it is three years old.",
  },
  height: { metres: 2.2, feet: 7, inches: 3 },
  weight: { kg: 80, lbs: 176.4 },
  src: { png: kangaskhanPng, gif: kangaskhanGif, box: kangaskhanBox },
};

export const LAPRAS: IPokemon = {
  species: PokemonSpecies.LAPRAS,
  type: [PokemonType.WATER, PokemonType.ICE],
  category: "Transport",
  pokedex: {
    number: 131,
    entry:
      "A POKÉMON that has been overhunted almost to extinction. It can ferry people on its back.",
  },
  height: { metres: 2.5, feet: 8, inches: 2 },
  weight: { kg: 220, lbs: 485 },
  src: { png: laprasPng, gif: laprasGif, box: laprasBox },
};

export const PIKACHU: IPokemon = {
  species: PokemonSpecies.PIKACHU,
  type: [PokemonType.ELECTRIC],
  category: "Mouse",
  pokedex: {
    number: 25,
    entry:
      "It raises its tail to check its surroundings. The tail is sometimes struck by lightning in this pose.",
  },
  height: { metres: 0.4, feet: 1, inches: 4 },
  weight: { kg: 6, lbs: 13.2 },
  src: { png: pikachuPng, gif: pikachuGif, box: pikachuBox },
};
