import { Text, Title } from "@mantine/core";
import styled from "styled-components";
import { IPokemon } from "../pokemon.types";
import PokemonSprite from "./PokemonSprite";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  pokemon: IPokemon;
}

const Container = styled.div`
  display: grid;
  align-items: center;
  whitespace: nowrap;

  grid-template-areas:
    "sprite sprite"
    "name name"
    "category height"
    "type weight"
    "description description"
    "power power";

  .name {
    grid-area: name;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    column-gap: 10px;
  }

  .category {
    grid-area: category;
  }

  .number {
    grid-area: number;
  }

  .type {
    grid-area: type;
  }

  .weight {
    grid-area: weight;
    overflow: scroll;
    line-height: 1;
  }

  .description {
    grid-area: description;
    margin-top: 10px;
  }

  .power {
    grid-area: power;
  }

  .card-children {
    grid-area: children;
  }
`;

const Sprite = styled(PokemonSprite)`
  grid-area: sprite;
  height: 80px;
  place-self: center;
  margin: 12px;
`;

const asDexNumber = (n: number): string => `${n}`.padStart(3, "0");

function PokemonCard({ className, style, pokemon }: Props): JSX.Element {
  return (
    <Container {...{ className, style }}>
      <Sprite {...{ pokemon }} />
      <div className="name">
        <Text size="sm">{asDexNumber(pokemon.pokedex.number)}</Text>
        <Title order={2}>{pokemon.species}</Title>
      </div>
      <Text className="category" size="xs">
        {pokemon.category} Pokémon
      </Text>
      <Text className="height" size="xs">
        HT {pokemon.height.metres} m ({pokemon.height.feet}'
        {pokemon.height.inches})
      </Text>
      <Text className="weight" size="xs">
        WT {pokemon.weight.kg} kg ({pokemon.weight.lbs} lbs)
      </Text>
      <Text className="type" size="xs">
        {pokemon.type.join(", ")}
      </Text>
      <Text className="description" size="sm">
        {pokemon.pokedex.entry}
      </Text>
    </Container>
  );
}

export default PokemonCard;
