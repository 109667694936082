import { Fragment } from "react";
import Tile from "./Tile";
import TileGrid from "./TileGrid";
import townSrc from "../assets/back/town-back.png";
import safariZoneSrc from "../assets/back/safari-zone-back.png";
import { isTownTile } from "../tiles.types";
import { Z_INDEX } from "../../../utils/constants";
import {
  Coordinate,
  PlacedTile,
  PlacedTileMap,
  PlayerMovement,
  ProposedTilePlacement,
  XYCoordinateString,
} from "../../game/game.types";
import { forwardRef } from "react";
import { Axis, GameHandlers } from "../../game/components/GameOngoing";
import TileRocketRoutes from "./TileRocketRoutes";

interface GridAdjustedRenderProps {
  gridAdjustX(n: number): number;
  gridAdjustY(n: number): number;
}

interface Props {
  className?: string;
  children?({
    gridAdjustX,
    gridAdjustY,
  }: GridAdjustedRenderProps): JSX.Element | null;
  handlers: GameHandlers;
  isPlacementLegal?: boolean;
  placedTiles: PlacedTileMap;
  placement?: ProposedTilePlacement;
  playerCoordinates: Coordinate;
  possibleMovements?: PlayerMovement[];
  renderOnPlayerTile?: React.FC;
  style?: React.CSSProperties;
  tileSize?: React.CSSProperties["width"];
  xAxis: Axis;
  yAxis: Axis;
}

function ActiveTilesWithRef(
  {
    className,
    children,
    isPlacementLegal,
    handlers,
    placement,
    playerCoordinates,
    possibleMovements = [],
    tileSize,
    placedTiles,
    renderOnPlayerTile: PlayerTile,
    style,
    xAxis,
    yAxis,
  }: Props,
  ref: React.ForwardedRef<HTMLDivElement>
): JSX.Element {
  const tileEntries = Object.entries(placedTiles) as [
    XYCoordinateString,
    PlacedTile
  ][];

  const coordinatesToShow: XYCoordinateString[] = [
    ...tileEntries.map(([coordinateStr]) => coordinateStr),
    ...possibleMovements.map(
      ({ to: { x, y } }) => `${x},${y}` as XYCoordinateString
    ),
  ];

  if (placement?.to) {
    coordinatesToShow.push(`${placement.to.x},${placement.to.y}`);
  }

  const gridAdjustX = (n: number) => n - xAxis.min + 1;
  const gridAdjustY = (n: number): number => yAxis.max - n + 1;

  return (
    <TileGrid ref={ref} {...{ className, style, tileSize, xAxis, yAxis }}>
      {tileEntries.map(([coordinates, { data, state, rocketRoutes }]) => {
        const [x, y] = coordinates.split(",").map((str) => parseInt(str));

        return (
          <Fragment key={coordinates}>
            <Tile
              key={`${coordinates}-tile`}
              backSrc={isTownTile(data) ? townSrc : safariZoneSrc}
              size={tileSize}
              style={{
                gridColumn: `${gridAdjustX(x)} / span 1`,
                gridRow: `${gridAdjustY(y)} / span 1`,
                justifySelf: "center",
                alignSelf: "center",
              }}
              {...{ ...data, ...state, isFlippedUp: true }}
            >
              {PlayerTile &&
                playerCoordinates.x === x &&
                playerCoordinates.y === y && <PlayerTile />}
            </Tile>
            {rocketRoutes && (
              <TileRocketRoutes
                coordinates={{ x, y }}
                {...{
                  gridAdjustX,
                  gridAdjustY,
                  rocketRoutes,
                }}
              />
            )}
          </Fragment>
        );
      })}
      {possibleMovements.map(({ medium, to: { x, y }, ...rest }) => (
        <div
          key={`highlight-(${x},${y})`}
          onClick={() =>
            handlers.onMovementSelect &&
            handlers.onMovementSelect({ medium, to: { x, y }, ...rest })
          }
          style={{
            gridColumn: `${gridAdjustX(x)} / span 1`,
            gridRow: `${gridAdjustY(y)} / span 1`,
            width: tileSize,
            height: tileSize,
            backgroundColor: "cyan",
            opacity: 0.3,
            boxSizing: "border-box",
            border: "2px solid cyan",
            borderRadius: "12.5%",
            zIndex: Z_INDEX.TILE + 1,
          }}
        />
      ))}
      {placement && placement.data && placement.to && (
        <>
          <Tile
            key={`(${placement.to.x},${placement.to.y})-placement`}
            active
            backSrc={isTownTile(placement.data) ? townSrc : safariZoneSrc}
            disabled={!isPlacementLegal}
            hotspots={{
              onClickCenter: handlers.onPlacementTileAffix,
              onClickCorner: handlers.onPlacementTileRotate,
            }}
            size={tileSize}
            style={{
              gridColumn: `${gridAdjustX(placement.to.x)} / span 1`,
              gridRow: `${gridAdjustY(placement.to.y)} / span 1`,
              justifySelf: "center",
              alignSelf: "center",
            }}
            {...placement.data}
            {...{ ...placement.state }}
          />
        </>
      )}
      {children && children({ gridAdjustX, gridAdjustY })}
    </TileGrid>
  );
}

const ActiveTiles = forwardRef(ActiveTilesWithRef);

export default ActiveTiles;
