import { Button, Modal, Space } from "@mantine/core";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import pokedexSrc from "../assets/pokedex.jpg";
import { GameHandlers } from "../../game/components/GameOngoing";
import { IPokemon } from "../pokemon.types";
import PokemonCard from "./PokemonCard";
import Pokeball from "../../bag/components/Pokeball";

interface Props {
  handlers: GameHandlers;
  isOpen?: boolean;
  onClose?(): void;
  pokemon?: IPokemon;
  title?: string;
  isCatchable?: boolean;
}

const ModalBody = styled(PokemonCard)`
  background-color: white;
  padding: 10px;
  border-radius: 5%;
  y-overflow: scroll;
`;

const StyledPokeball = styled(Pokeball)`
  height: 20px;
`;

function PokemonModal({
  handlers,
  isCatchable,
  isOpen,
  onClose,
  pokemon,
  title = "Pokédex",
}: Props): JSX.Element {
  const vh100 = use100vh() ?? "calc(var(--vh, vh) * 100)";

  return (
    <Modal
      opened={!!isOpen}
      onClose={() => onClose && onClose()}
      styles={{
        modal: {
          backgroundImage: `url("${pokedexSrc}")`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          height: `calc(${vh100} * 0.8)`,
          width: `calc(${vh100} * 0.8 * 0.562)`,
        },
      }}
      // title={<Title order={3}>{title}</Title>}
    >
      {pokemon && <ModalBody {...{ pokemon }} />}
      <Space />
      {pokemon && (
        <Button
          compact
          disabled={!isCatchable}
          fullWidth
          variant="gradient"
          gradient={{ from: "red", to: "green" }}
          onClick={() => handlers.onWildPokemonCatch(pokemon)}
        >
          <StyledPokeball /> {isCatchable ? "Catch" : "Already owned"}{" "}
          <StyledPokeball />
        </Button>
      )}
      <Button
        compact
        fullWidth
        variant="light"
        onClick={() => onClose && onClose()}
      >
        Back to map
      </Button>
    </Modal>
  );
}

export default PokemonModal;
