import { forwardRef } from "react";
import styled from "styled-components";
import { Axis } from "../../game/components/GameOngoing";

type Props = React.PropsWithChildren<{
  className?: string;
  style?: React.CSSProperties;
  tileSize?: React.CSSProperties["width"];
  xAxis: Axis;
  yAxis: Axis;
}>;

const Grid = styled.div`
  display: grid;
  gap: 5px;
`;

function TileGridWithRef(
  { className, style, children, tileSize = "250px", xAxis, yAxis }: Props,
  ref: React.ForwardedRef<HTMLDivElement>
): JSX.Element {
  const xRange = xAxis.max - xAxis.min;
  const yRange = yAxis.max - yAxis.min;
  const gridTemplateRows = `repeat(${xRange + 1}, ${tileSize})`;
  const gridTemplateColumns = `repeat(${yRange + 1}, ${tileSize})`;

  return (
    <Grid
      className={className}
      ref={ref}
      style={{
        gridTemplateColumns,
        gridTemplateRows,
        // width: `calc(${tileSize} * ${xRange + 1})`,
        // height: `calc(${tileSize} * ${yRange + 1})`,
        columnGap: xAxis.gap,
        rowGap: yAxis.gap,
        ...style,
      }}
    >
      {children}
    </Grid>
  );
}

const TileGrid = forwardRef<HTMLDivElement, Props>(TileGridWithRef);

export default TileGrid;
