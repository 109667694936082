export interface TileBase {
  id: string;
  frontSrc: string;
  type: TileType;
  connections: TileConnectionMap;
}

export type TileConnectionMap = Record<Direction, TileConnectionPath>;

export enum Direction {
  UP = "up",
  DOWN = "down",
  LEFT = "left",
  RIGHT = "right",
}

export enum TileConnectionPath {
  CUTTABLE_HEDGE = "cuttable-hedge",
  ENTER_SAFARI_ZONE = "enter-safari-zone",
  EXIT_SAFARI_ZONE = "exit-safari-zone",
  FENCING = "fencing",
  PAVED_PATH = "paved-path",
  SANDY_PATH = "sandy-path",
  TREES = "trees",
  WATER = "water",
}

export enum TileType {
  SAFARI_ZONE = "Safari Zone",
  TOWN = "Town",
}

export interface TownTile extends TileBase {
  type: TileType.TOWN;
}

export interface SafariZoneTile extends TileBase {
  type: TileType.SAFARI_ZONE;
}

export type MapTile = TownTile | SafariZoneTile;

export function isSafariZoneTile(
  tile: MapTile | TileBase
): tile is SafariZoneTile {
  return tile.type === TileType.SAFARI_ZONE;
}

export function isTownTile(tile: MapTile | TileBase): tile is TownTile {
  return tile.type === TileType.TOWN;
}

export const safariZoneTile = ({
  frontSrc,
  connections,
}: Pick<TileBase, "frontSrc" | "connections">): Readonly<SafariZoneTile> =>
  Object.freeze({
    id: frontSrc,
    frontSrc,
    type: TileType.SAFARI_ZONE,
    connections,
  });

export const townTile = ({
  frontSrc,
  connections,
}: Pick<TileBase, "frontSrc" | "connections">): Readonly<TownTile> =>
  Object.freeze({
    id: frontSrc,
    frontSrc,
    type: TileType.TOWN,
    connections,
  });
