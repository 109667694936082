import Div100vh from "react-div-100vh";
import useMobileViewport from "../hooks/useMobileViewport";

type Props = React.PropsWithChildren<unknown>;

function Viewport({ children }: Props): JSX.Element {
  useMobileViewport();

  return <Div100vh className="viewport">{children}</Div100vh>;
}

export default Viewport;
