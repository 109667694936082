import { Z_INDEX } from "../../../utils/constants";
import { Coordinate } from "../../game/game.types";
import { Direction } from "../tiles.types";

interface Props {
  coordinates: Coordinate;
  gridAdjustX(n: number): number;
  gridAdjustY(n: number): number;
  rocketRoutes: Partial<Record<Direction, boolean>>;
}

function TileRocketRoutes({
  coordinates: { x, y },
  gridAdjustX,
  gridAdjustY,
  rocketRoutes,
}: Props): JSX.Element {
  return (
    <>
      {Object.keys(rocketRoutes).map((direction) => (
        <span
          key={`(${x},${y})-${direction}`}
          style={{
            border: "2px solid green",
            backgroundColor: "lightgray",
            gridColumn: gridAdjustX(x),
            gridRow: gridAdjustY(y),
            justifySelf:
              direction === Direction.LEFT
                ? "start"
                : direction === Direction.RIGHT
                ? "end"
                : "center",
            alignSelf:
              direction === Direction.UP
                ? "start"
                : direction === Direction.DOWN
                ? "end"
                : "center",
            zIndex: Z_INDEX.TILE + 1,
            fontSize: "2rem",
          }}
        >
          🚪
        </span>
      ))}
    </>
  );
}

export default TileRocketRoutes;
