import { TileConnectionPath, townTile } from "../tiles.types";
import fountainSrc from "../assets/town/fountain.png";
import gymSrc from "../assets/town/gym.png";
import homeSrc from "../assets/town/home.png";
import labSrc from "../assets/town/oaks-lab.png";
import pokecenterSrc from "../assets/town/pokecenter.png";
import pokemartSrc from "../assets/town/pokemart.png";
import portSrc from "../assets/town/port.png";
import safariZoneSrc from "../assets/town/safari-zone.png";

export const FOUNTAIN = townTile({
  frontSrc: fountainSrc,
  connections: {
    up: TileConnectionPath.PAVED_PATH,
    down: TileConnectionPath.PAVED_PATH,
    left: TileConnectionPath.PAVED_PATH,
    right: TileConnectionPath.PAVED_PATH,
  },
});

export const GYM = townTile({
  frontSrc: gymSrc,
  connections: {
    up: TileConnectionPath.PAVED_PATH,
    down: TileConnectionPath.CUTTABLE_HEDGE,
    left: TileConnectionPath.TREES,
    right: TileConnectionPath.PAVED_PATH,
  },
});

export const HOME = townTile({
  frontSrc: homeSrc,
  connections: {
    up: TileConnectionPath.PAVED_PATH,
    down: TileConnectionPath.TREES,
    left: TileConnectionPath.TREES,
    right: TileConnectionPath.TREES,
  },
});

export const OAKS_LAB = townTile({
  frontSrc: labSrc,
  connections: {
    up: TileConnectionPath.PAVED_PATH,
    down: TileConnectionPath.TREES,
    left: TileConnectionPath.TREES,
    right: TileConnectionPath.TREES,
  },
});

export const POKECENTER = townTile({
  frontSrc: pokecenterSrc,
  connections: {
    up: TileConnectionPath.PAVED_PATH,
    down: TileConnectionPath.PAVED_PATH,
    left: TileConnectionPath.PAVED_PATH,
    right: TileConnectionPath.CUTTABLE_HEDGE,
  },
});

export const POKEMART = townTile({
  frontSrc: pokemartSrc,
  connections: {
    up: TileConnectionPath.TREES,
    down: TileConnectionPath.PAVED_PATH,
    left: TileConnectionPath.PAVED_PATH,
    right: TileConnectionPath.CUTTABLE_HEDGE,
  },
});

export const PORT = townTile({
  frontSrc: portSrc,
  connections: {
    up: TileConnectionPath.WATER,
    down: TileConnectionPath.PAVED_PATH,
    left: TileConnectionPath.WATER,
    right: TileConnectionPath.CUTTABLE_HEDGE,
  },
});

export const SAFARI_ZONE = townTile({
  frontSrc: safariZoneSrc,
  connections: {
    up: TileConnectionPath.ENTER_SAFARI_ZONE,
    down: TileConnectionPath.PAVED_PATH,
    left: TileConnectionPath.TREES,
    right: TileConnectionPath.PAVED_PATH,
  },
});
