import { Button, Modal, Space, Title } from "@mantine/core";
import EncounterCard from "../../encounter/components/EncounterCard";
import {
  EncounterHour,
  IEncounterCard,
} from "../../encounter/types/encounter.types";
import { GameHandlers } from "./GameOngoing";

interface Props {
  card?: IEncounterCard;
  handlers: GameHandlers;
  active?: EncounterHour | "item";
  isOpen?: boolean;
  onClose?(): void;
  title?: string;
}

function EncounterModal({
  card,
  handlers,
  active,
  isOpen,
  onClose,
  title = "Encounter card",
}: Props): JSX.Element {
  return (
    <Modal
      opened={!!isOpen}
      onClose={() => onClose && onClose()}
      title={<Title order={3}>{title}</Title>}
    >
      {card && <EncounterCard {...{ active, card, handlers }} />}
      <Space />
      <Button
        compact
        fullWidth
        onClick={() => onClose && onClose()}
        variant="outline"
      >
        Back to map
      </Button>
    </Modal>
  );
}

export default EncounterModal;
