import { animated, AnimatedProps } from "react-spring";
import pokeballSrc from "../assets/items/pokeball.png";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  onClick?(): void;
}

function Pokeball({
  className,
  style,
  onClick,
}: AnimatedProps<Props>): JSX.Element {
  return (
    <animated.img
      {...{ className, style, onClick }}
      alt="pokeball"
      src={pokeballSrc}
    />
  );
}

export default Pokeball;
