export enum ItemType {
  CHOICE_BAND = "Choice Band",
  HM01 = "HM01 (Cut)",
  HYPER_POTION = "Hyper Potion",
  MAGNET = "Magnet",
  POKEBALL = "Pokéball",
  POKERADAR = "Poké Radar",
  POKEFLUTE = "Pokéflute",
  SITRUS_BERRY = "Sitrus Berry",
  ROCKET_UNIFORM = "Rocket Uniform",
}

export interface Item {
  type: ItemType;
  imageSrc: string;
  descriptionHeader?: string;
  descriptionBody: string;
  persistence: ItemPersistence;
}

export enum ItemPersistence {
  SINGLE_BATTLE = "One-time use in a battle of your choice, then is discarded.",
  SINGLE_USE = "Discard after single use.",
  WHILST_HELD = "Lasts until item is discarded.",
}
