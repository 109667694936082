import { Direction } from "../../tiles/tiles.types";
import { Coordinate } from "../game.types";

export const translate = (
  { x, y }: Coordinate,
  direction: Direction
): Coordinate => {
  switch (direction) {
    case Direction.UP:
      return { x, y: y + 1 };
    case Direction.DOWN:
      return { x, y: y - 1 };
    case Direction.RIGHT:
      return { x: x + 1, y };
    case Direction.LEFT:
      return { x: x - 1, y };
  }
};
