import Board from "./modules/game/components/Board";
import Viewport from "./components/Viewport";

function App(): JSX.Element {
  return (
    <Viewport>
      <Board />
    </Viewport>
  );
}

export default App;
