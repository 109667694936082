import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";

interface InitialScroll<
  TargetElement extends HTMLElement = HTMLElement,
  ContainerElement extends HTMLElement = HTMLElement
> {
  scrollParent: React.RefObject<ContainerElement>;
  scrollTarget: React.RefObject<TargetElement>;
}

function useInitialScroll<
  TargetElement extends HTMLElement = HTMLElement,
  ContainerElement extends HTMLElement = HTMLElement
>(): InitialScroll<TargetElement, ContainerElement> {
  const scrollTargetRef = useRef<TargetElement>(null);
  const scrollParentRef = useRef<ContainerElement>(null);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    const scrollTargetElement = scrollTargetRef.current;
    const scrollParentElement = scrollParentRef.current;
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );

    if (isFirstLoad && scrollTargetElement && scrollParentElement) {
      const { offsetLeft, offsetTop } = scrollTargetElement;
      scrollParentElement.scroll({
        left: offsetLeft - 0.5 * vw - 30,
        top: offsetTop - 0.8 * vh,
      });
      setIsFirstLoad(false);
    }
  }, [isFirstLoad]);

  return { scrollParent: scrollParentRef, scrollTarget: scrollTargetRef };
}

export default useInitialScroll;
