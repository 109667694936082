import * as GAME_MOVES from "./moves";
import {
  Direction,
  MapTile,
  SafariZoneTile,
  TileConnectionPath,
  TownTile,
} from "../tiles/tiles.types";
import { MoveFn } from "boardgame.io";
import { Drop } from "ts-toolbelt/out/List/Drop";
import { TOWN_TILES } from "../tiles/data";
import {
  IEncounterCard,
  EncounterHour,
} from "../encounter/types/encounter.types";
import { ItemType } from "../encounter/types/item.types";
import {
  IPokemon,
  PokemonSpecies,
  PokemonVariant,
} from "../pokemon/pokemon.types";

export interface GameState {
  encounterCards: {
    active?: IEncounterCard;
    deck: IEncounterCard[];
    discard: IEncounterCard[];
    itemDiscovery?: IEncounterCard;
  };
  hoursLeft: EncounterHour;
  lastMovement?: PlayerMovement;
  placedTiles: PlacedTileMap;
  placement?: ProposedTilePlacement;
  player: {
    coordinates: Coordinate;
    items: Partial<Record<ItemType, boolean>>;
    pokemon: { [PokemonSpecies.PIKACHU]: true } & Partial<
      Record<PokemonSpecies, boolean | PokemonVariant>
    >;
    /** Stats before application of held items */
    stats: PlayerRawStats;
  };
  tileStacks: {
    town: TownTile[];
    safariZone: SafariZoneTile[];
  };
  turnStage: TurnStage;
  unexploredPaths: number;
  wildPokemon: {
    active?: IPokemon;
  };
}

export type PlayerRawStats = Record<"hp" | "attack", number>;

export enum TurnStage {
  MOVEMENT_CHOICE = "movementChoice",
  TILE_PLACEMENT = "tilePlacement",
  ENCOUNTER_CARD = "encounterCard",
  ITEM_DISCOVERY = "item discovery",
  WILD_POKEMON = "wild Pokémon",
  OPPONENT_FLEE = "opponent-flee",
  FORAGING = "foraging",
  ROCKET_ATTACK_ROUTE_CHOICE = "rocket attack - route choice",
}

export type GameMove = keyof typeof GAME_MOVES;
type MoveDispatcher<MoveDefinition extends MoveFn> = (
  ...args: Drop<Parameters<MoveDefinition>, 2>
) => ReturnType<MoveDefinition>;
export type Moves = {
  [P in GameMove]: MoveDispatcher<typeof GAME_MOVES[P]>;
};
export { GAME_MOVES };

export type Coordinate = Record<"x" | "y", number>;
export type XYCoordinateString = `${number},${number}`;
export type PlacedTileMap = Record<XYCoordinateString, PlacedTile>;

export const INITIAL_TILE_MAP: PlacedTileMap = {
  "0,0": {
    coordinates: { x: 0, y: 0 },
    data: TOWN_TILES.HOME,
  },
};

export interface ProposedTilePlacement extends ActiveTileBase {
  to: Coordinate;
}

export interface ActiveTileBase {
  data: MapTile;
  state?: {
    isFlippedUp?: boolean;
    rotate?: number;
  };
  /** Ignores rotation */
  rocketRoutes?: Partial<Record<Direction, true>>;
}

export interface PlacedTile extends ActiveTileBase {
  coordinates: Coordinate;
}

export enum MovementMedium {
  CHANGE_ZONE = "changeZone",
  FLY = "fly",
  WALK = "walk",
  PAVED_WALK = "pavedWalk",
  SANDY_WALK = "sandyWalk",
}

export interface PlayerMovement {
  to: Coordinate;
  connection: TileConnectionPath;
  medium: MovementMedium;
  existingTile?: PlacedTile;
}
