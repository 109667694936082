import { Button, Text } from "@mantine/core";
import { IEncounterCard } from "../../encounter/types/encounter.types";
import { TurnStage } from "../game.types";
import { GameHandlers } from "./GameOngoing";

type StageInformationProps = React.PropsWithChildren<{
  className?: string;
  style?: React.CSSProperties;
  encounterCard?: IEncounterCard;
  handlers: GameHandlers;
  onEncounterCardView?(): void;
  onItemDiscoveryView?(): void;
  onWildPokemonView?(): void;
  turnStage: TurnStage;
}>;

function StageInformation({
  children,
  className,
  handlers,
  style,
  ...rest
}: StageInformationProps): JSX.Element {
  return (
    <div {...{ className, style }}>
      <Body
        {...{
          handlers,
          ...rest,
        }}
      />
      {children}
    </div>
  );
}

type BodyProps = Omit<
  StageInformationProps,
  "children" | "className" | "style"
>;

const Body = ({
  encounterCard,
  handlers,
  onEncounterCardView,
  onItemDiscoveryView,
  onWildPokemonView,
  turnStage,
}: BodyProps): JSX.Element | null => {
  switch (turnStage) {
    case TurnStage.MOVEMENT_CHOICE:
      return <Text>Choose an area to move into.</Text>;

    case TurnStage.TILE_PLACEMENT:
      return (
        <Text>
          You can{" "}
          <Button
            compact
            onClick={handlers.onPlacementTileRotate}
            variant="outline"
          >
            rotate
          </Button>{" "}
          the tile by clicking on a corner, and{" "}
          <Button
            compact
            onClick={handlers.onPlacementTileAffix}
            variant="outline"
          >
            place
          </Button>{" "}
          it by clicking its center.
        </Text>
      );

    case TurnStage.ENCOUNTER_CARD:
      return (
        <Text>
          You can resolve your drawn{" "}
          <Button compact onClick={onEncounterCardView} variant="outline">
            Encounter
          </Button>{" "}
          card by following its instructions.
        </Text>
      );

    case TurnStage.ITEM_DISCOVERY:
      return (
        <Text>
          You have discovered an{" "}
          <Button compact onClick={onItemDiscoveryView} variant="outline">
            Item
          </Button>{" "}
          , which you can either{" "}
          <Button
            compact
            onClick={() =>
              encounterCard && handlers.onItemTake(encounterCard.item)
            }
            variant="outline"
          >
            take
          </Button>{" "}
          or{" "}
          <Button compact onClick={handlers.onItemSkip} variant="outline">
            leave
          </Button>
          .
        </Text>
      );

    case TurnStage.WILD_POKEMON:
      return (
        <Text>
          A wild{" "}
          <Button
            compact
            onClick={() => onWildPokemonView && onWildPokemonView()}
            variant="outline"
          >
            Pokémon
          </Button>{" "}
          appeared!
        </Text>
      );

    case TurnStage.OPPONENT_FLEE:
      return <Text>Select a tile to retreat back into.</Text>;

    case TurnStage.FORAGING:
      return (
        <Text>
          You can{" "}
          <Button compact onClick={handlers.onForageAccept} variant="outline">
            forage
          </Button>{" "}
          for berries to recover 3 HP (at the cost of 1 Encounter card), or{" "}
          <Button compact onClick={handlers.onForageSkip} variant="outline">
            skip
          </Button>
          .
        </Text>
      );
    case TurnStage.ROCKET_ATTACK_ROUTE_CHOICE:
      return <Text>You must select a Rocket Ambush route.</Text>;

    default:
      return null;
  }
};

export default StageInformation;
