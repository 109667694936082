import { Accordion, Button, Space, Text, Title } from "@mantine/core";
import styled from "styled-components";
import { Item, ItemPersistence } from "../../encounter/types/item.types";
import { GameHandlers } from "../../game/components/GameOngoing";
import cardBackgroundSrc from "../assets/bag-open.png";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  handlers: GameHandlers;
  items: Item[];
}

const Container = styled.div`
  background-image: url("${cardBackgroundSrc}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 50vh;
  height: calc(var(--vh, 1vh) * 50);
  overflow-y: scroll;

  .title {
    display: flex;
    align-items: center;
  }
`;

const EmptyBagMessage = styled(Text)`
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 10px;
`;

function BagContents({
  className,
  handlers,
  style,
  items,
}: Props): JSX.Element {
  return (
    <Container {...{ className, style }}>
      {items.length ? (
        <Accordion
          multiple
          offsetIcon={false}
          styles={{
            content: {
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            },
            control: {
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              // "&:hover": {
              //   backgroundColor: "rgba(255, 255, 255, 1)",
              // },
            },
          }}
        >
          {items.map((item) => {
            return (
              <Accordion.Item
                key={item.type}
                label={
                  <div className="title">
                    <img
                      alt={item.type}
                      src={item.imageSrc}
                      style={{ height: "32px" }}
                    />
                    <Title order={5}>{item.type}</Title>
                  </div>
                }
              >
                <p>
                  {item.descriptionHeader && (
                    <>
                      <Text component="span" weight="bold" size="sm">
                        {item.descriptionHeader}
                      </Text>
                      <Text component="span" size="sm">
                        {" "}
                        -{" "}
                      </Text>
                    </>
                  )}
                  <Text component="span" size="sm">
                    {item.descriptionBody}
                  </Text>
                </p>
                <Space />
                <Text component="p" size="xs">
                  {" (" + item.persistence + ")"}
                </Text>
                <Buttons>
                  {item.persistence === ItemPersistence.SINGLE_USE && (
                    <Button
                      compact
                      onClick={() => handlers.onItemSingleUse(item)}
                    >
                      Use
                    </Button>
                  )}
                  <Button
                    compact
                    variant="light"
                    color="red"
                    onClick={() => handlers.onItemDrop(item)}
                  >
                    Drop
                  </Button>
                </Buttons>
              </Accordion.Item>
            );
          })}
        </Accordion>
      ) : (
        <EmptyBagMessage className="description" weight="bold">
          No items - bag is empty!
        </EmptyBagMessage>
      )}
    </Container>
  );
}

export default BagContents;
