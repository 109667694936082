import {
  Direction,
  TileConnectionPath,
  TileConnectionMap,
} from "../../tiles/tiles.types";
import { ActiveTileBase, PlacedTile } from "../game.types";

function isConnectedByPath(
  proposedPlacement: ActiveTileBase,
  /**
   * From above is connecting by down, from right is connecting by left, etc.
   */
  connectingHingeDirection: Direction,
  connectingTile: ActiveTileBase | PlacedTile
): boolean {
  const connectionFromProposedTile = getConnectingHinge(
    proposedPlacement.data.connections,
    proposedPlacement.state?.rotate ?? 0,
    connectingHingeDirection
  );

  const reverseDirection = rotateDirection180(connectingHingeDirection);

  const connectionBackToProposedTile = getConnectingHinge(
    connectingTile.data.connections,
    connectingTile.state?.rotate ?? 0,
    reverseDirection
  );

  return arePathMatches(
    connectionFromProposedTile,
    connectionBackToProposedTile,
    connectingTile.rocketRoutes?.[reverseDirection]
  );
}

const arePathMatches = (
  a: TileConnectionPath,
  b: TileConnectionPath,
  isRocketOpenOnB?: boolean
): boolean => {
  // special case of safari zone entrance and exit
  const specialEntranceCheck = oneOrOther(
    {
      a,
      b,
    },
    TileConnectionPath.ENTER_SAFARI_ZONE
  );

  if (specialEntranceCheck.hasMatch) {
    return (
      specialEntranceCheck.otherPath === TileConnectionPath.EXIT_SAFARI_ZONE
    );
  }

  const specialExitCheck = oneOrOther(
    {
      a,
      b,
    },
    TileConnectionPath.EXIT_SAFARI_ZONE
  );

  if (specialExitCheck.hasMatch) {
    return specialExitCheck.otherPath === TileConnectionPath.ENTER_SAFARI_ZONE;
  }

  // ordinary path check
  const hasFirstPath = !![a, b].find((path) =>
    [TileConnectionPath.PAVED_PATH, TileConnectionPath.SANDY_PATH].includes(
      path
    )
  );

  return hasFirstPath && !!(a === b || isRocketOpenOnB);
};

export const rotateHinges = (
  defaultConnectionMap: TileConnectionMap,
  rotation: number
): TileConnectionMap => ({
  [Direction.UP]: getConnectingHinge(
    defaultConnectionMap,
    rotation,
    Direction.UP
  ),
  [Direction.DOWN]: getConnectingHinge(
    defaultConnectionMap,
    rotation,
    Direction.DOWN
  ),
  [Direction.LEFT]: getConnectingHinge(
    defaultConnectionMap,
    rotation,
    Direction.LEFT
  ),
  [Direction.RIGHT]: getConnectingHinge(
    defaultConnectionMap,
    rotation,
    Direction.RIGHT
  ),
});

export const getConnectingHinge = (
  defaultConnectionMap: TileConnectionMap,
  rotation: number,
  connectionDirection: Direction
): TileConnectionPath => {
  const netClockwiseRotation = rotation % 360;

  if (netClockwiseRotation === 90) {
    return defaultConnectionMap[rotateDirection90(connectionDirection)];
  } else if (netClockwiseRotation === 180) {
    return defaultConnectionMap[rotateDirection180(connectionDirection)];
  } else if (netClockwiseRotation === 270) {
    return defaultConnectionMap[hingeInPlaceFrom270(connectionDirection)];
  } else {
    return defaultConnectionMap[connectionDirection];
  }
};

const rotateDirection90 = (direction: Direction): Direction => {
  switch (direction) {
    case Direction.UP:
      return Direction.LEFT;
    case Direction.RIGHT:
      return Direction.UP;
    case Direction.DOWN:
      return Direction.RIGHT;
    case Direction.LEFT:
      return Direction.DOWN;
  }
};

export const rotateDirection180 = (direction: Direction): Direction => {
  switch (direction) {
    case Direction.UP:
      return Direction.DOWN;
    case Direction.RIGHT:
      return Direction.LEFT;
    case Direction.DOWN:
      return Direction.UP;
    case Direction.LEFT:
      return Direction.RIGHT;
  }
};

const hingeInPlaceFrom270 = (direction: Direction): Direction => {
  switch (direction) {
    case Direction.UP:
      return Direction.RIGHT;
    case Direction.RIGHT:
      return Direction.DOWN;
    case Direction.DOWN:
      return Direction.LEFT;
    case Direction.LEFT:
      return Direction.UP;
  }
};

const oneOrOther = (
  { a, b }: { a: TileConnectionPath; b: TileConnectionPath },
  matcher: TileConnectionPath
): { hasMatch: true; otherPath: TileConnectionPath } | { hasMatch: false } => {
  if (a === matcher) {
    return { hasMatch: true, otherPath: b };
  } else if (b === matcher) {
    return { hasMatch: true, otherPath: a };
  } else {
    return { hasMatch: false };
  }
};

export default isConnectedByPath;
