import { Text } from "@mantine/core";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  hp: number;
  hpMax?: number;
  attack: number;
}

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  column-gap: 20px;

  .hp-container {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    align-items: center;
    column-gap: 10px;
  }

  .bar-container {
    flex-grow: 1;
    display: grid;
    grid-template-areas: "hp-meter";
  }

  .bar-label {
    grid-area: hp-meter;
    padding-left: 5px;
  }

  .bar-outline {
    grid-area: hp-meter;
    border-left: 2px solid black;
    border-bottom: 2px solid black;
    height: 100%;
  }

  .bar-fill {
    height: 100%;
    width: 0%;
  }
`;

function VitalStats({
  className,
  style,
  hp,
  hpMax = 12,
  attack,
}: Props): JSX.Element {
  const { healthPct } = useSpring({
    healthPct: (hp / hpMax) * 100,
  });

  return (
    <Container {...{ className, style }}>
      <div>
        <Text component="span" weight="bold">
          Attack:{" "}
        </Text>
        <Text component="span">{attack}</Text>
      </div>
      <div className="hp-container">
        <Text component="span" weight="bold">
          HP:{" "}
        </Text>
        <div className="bar-container">
          <div className="bar-outline">
            <animated.div
              className="bar-fill"
              style={{
                backgroundColor: healthPct.to((v) =>
                  v > 50 ? "#70E69B" : v > 20 ? "#EAD834" : "#DE5441"
                ),
                width: healthPct.to((v) => `${v}%`),
              }}
            />
          </div>
          <Text className="bar-label" size="xs">
            {hp}/{hpMax}
          </Text>
        </div>
      </div>
    </Container>
  );
}

export default VitalStats;
