import { Direction, TileConnectionPath } from "../../tiles/tiles.types";
import {
  Coordinate,
  MovementMedium,
  PlacedTile,
  PlacedTileMap,
  PlayerMovement,
  XYCoordinateString,
} from "../game.types";
import isConnectedByPath, {
  getConnectingHinge,
  rotateDirection180,
} from "./isConnectedByPath";

const ZONE_TRANSITION_PATHS = [
  TileConnectionPath.ENTER_SAFARI_ZONE,
  TileConnectionPath.EXIT_SAFARI_ZONE,
];

export const NORMAL_WALKABLE_PATHS = [
  TileConnectionPath.PAVED_PATH,
  TileConnectionPath.SANDY_PATH,
];

const DEFAULT_MOVEABLE_PATHS = [
  ...NORMAL_WALKABLE_PATHS,
  ...ZONE_TRANSITION_PATHS,
];

// todo - add cut, teleport + flight

interface MovementOpts {
  newPaths?: boolean;
  existingPaths?: boolean;
}

function getPossibleMovements(
  placedTiles: PlacedTileMap,
  playerCoordinates: Coordinate,
  { newPaths = true, existingPaths = true }: MovementOpts = {}
): PlayerMovement[] {
  const { x, y } = playerCoordinates;
  const currentXY: XYCoordinateString = `${x},${y}`;
  const currentTile = placedTiles[currentXY];

  const possibleMovements: PlayerMovement[] = [];

  // const isMoveableAtDirection = (d: Direction): boolean =>
  //   currentTile.rocketRoutes?.[d] ||
  //   DEFAULT_MOVEABLE_PATHS.includes(
  //     getConnectingHinge(
  //       currentTile.data.connections,
  //       currentTile.state?.rotate ?? 0,
  //       d
  //     )
  //   );

  const walk = (d: Direction, coords: Coordinate): PlayerMovement => ({
    medium: MovementMedium.WALK,
    connection: getConnectingHinge(
      currentTile.data.connections,
      currentTile.state?.rotate ?? 0,
      d
    ),
    to: coords,
    existingTile: placedTiles[`${coords.x},${coords.y}`],
  });

  const coordMap = getRelativeCoordinates({ x, y });
  const coordEntries = Object.entries(coordMap) as [Direction, Coordinate][];

  for (const [direction, coords] of coordEntries) {
    if (
      isMoveableAtDirection(currentTile, placedTiles, direction, {
        newPaths,
        existingPaths,
      })
    ) {
      possibleMovements.push(walk(direction, coords));
    }
  }

  return possibleMovements;
}

export const getRelativeCoordinates = ({
  x,
  y,
}: Coordinate): Record<Direction, Coordinate> => ({
  [Direction.UP]: { x, y: y + 1 },
  [Direction.DOWN]: { x, y: y - 1 },
  [Direction.RIGHT]: { x: x + 1, y },
  [Direction.LEFT]: { x: x - 1, y },
});

const isMoveableAtDirection = (
  currentTile: PlacedTile,
  placedTiles: PlacedTileMap,
  d: Direction,
  { newPaths, existingPaths }: { newPaths: boolean; existingPaths: boolean } = {
    newPaths: true,
    existingPaths: true,
  }
): boolean => {
  const relativeCoords = getRelativeCoordinates(currentTile.coordinates);
  const { x: newX, y: newY } = relativeCoords[d];
  const extantPlacement: PlacedTile | undefined =
    placedTiles[`${newX},${newY}`];

  if (currentTile.rocketRoutes?.[d]) {
    return newPaths ? true : !extantPlacement;
  }

  if (
    DEFAULT_MOVEABLE_PATHS.includes(
      getConnectingHinge(
        currentTile.data.connections,
        currentTile.state?.rotate ?? 0,
        d
      )
    )
  ) {
    return !extantPlacement
      ? newPaths
      : existingPaths
      ? isConnectedByPath(extantPlacement, rotateDirection180(d), currentTile)
      : false;
  }

  if (existingPaths) {
    return !!extantPlacement?.rocketRoutes?.[rotateDirection180(d)];
  } else {
    return false;
  }
};

export default getPossibleMovements;
