import { Button } from "@mantine/core";
import { TurnStage } from "../game.types";
import styled from "styled-components";
import { GameHandlers } from "./GameOngoing";

interface Props {
  className?: string;
  handlers: GameHandlers;
  onEncounterCardView?(): void;
  onItemDiscoveryView?(): void;
  onWildPokemonView?(): void;
  style?: React.CSSProperties;
  turnStage: TurnStage;
}

const Container = styled.div``;

function ActionButtons({
  className,
  style,
  handlers,
  onEncounterCardView,
  onItemDiscoveryView,
  onWildPokemonView,
  turnStage,
}: Props): JSX.Element | null {
  if (turnStage === TurnStage.ENCOUNTER_CARD) {
    return (
      <Container {...{ className, style }}>
        <Button
          onClick={() => onEncounterCardView && onEncounterCardView()}
          compact
          size="xl"
          color="red"
          fullWidth
        >
          View encounter card
        </Button>
      </Container>
    );
  }

  if (turnStage === TurnStage.ITEM_DISCOVERY) {
    return (
      <Container {...{ className, style }}>
        <Button
          onClick={() => onItemDiscoveryView && onItemDiscoveryView()}
          compact
          size="xl"
          color="red"
          fullWidth
        >
          View item discovered
        </Button>
      </Container>
    );
  }

  if (turnStage === TurnStage.WILD_POKEMON) {
    return (
      <Container {...{ className, style }}>
        <Button
          onClick={() => onWildPokemonView && onWildPokemonView()}
          compact
          size="xl"
          color="red"
          fullWidth
        >
          View wild Pokémon
        </Button>
      </Container>
    );
  }

  if (turnStage === TurnStage.FORAGING) {
    return (
      <Container {...{ className, style }}>
        <Button
          onClick={() => handlers.onForageAccept && handlers.onForageAccept()}
          compact
          size="xl"
          fullWidth
        >
          Forage
        </Button>
        <Button
          onClick={() => handlers.onForageSkip && handlers.onForageSkip()}
          compact
          size="xl"
          variant="light"
          fullWidth
        >
          Skip foraging
        </Button>
      </Container>
    );
  }

  return null;
}

export default ActionButtons;
