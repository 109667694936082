import { Text } from "@mantine/core";
import styled from "styled-components";
import { EncounterHour } from "../../encounter/types/encounter.types";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  cardsLeft: number;
  hoursLeft: EncounterHour;
}

const DeckStats = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 20px;
`;

function Timer({ className, style, cardsLeft, hoursLeft }: Props): JSX.Element {
  return (
    <DeckStats {...{ className, style }}>
      <div>
        <Text component="span" weight="bold">
          T-{hoursLeft}
        </Text>
        <Text component="span"> to game end</Text>
      </div>
      <div>
        <Text component="span" weight="bold">
          {cardsLeft} x
        </Text>
        <Text component="span"> Encounters to T-{hoursLeft - 1}</Text>
      </div>
    </DeckStats>
  );
}

export default Timer;
