import { useEffect } from "react";
import styled from "styled-components";
import Div100vh from "react-div-100vh";
import { BoardProps } from "boardgame.io/react";
import {
  selectActiveDrawnEncounterCard,
  selectActiveWildPokemon,
  selectCurrentPlayerTileRocketRoutes,
  selectGameHoursLeft,
  selectHasLegalPlacement,
  selectIsRocketAttackDue,
  selectItemDiscoveryCard,
  selectPartyPokemon,
  selectPlayerComputedAttack,
  selectPlayerComputedHealth,
  selectPlayerCoordinates,
  selectPlayerItemsList,
  selectPossiblePlayerMovements,
  selectRemainingEncounterCardsCount,
} from "../selectors/game.selectors";
import {
  TurnStage,
  GameState,
  Moves,
  Coordinate,
  PlayerMovement,
} from "../game.types";
import Player from "../../player/components/Player";
import { Z_INDEX } from "../../../utils/constants";
import { useState } from "react";
import StageInformation from "./StageInformation";
import BoardTiles from "./BoardTiles";
import ActionButtons from "./ActionButtons";
import useInitialScroll from "../hooks/useInitialScroll";
import Timer from "./Timer";
import VitalStats from "./VitalStats";
import { Direction } from "../../tiles/tiles.types";
import { EventEncounter } from "../../encounter/types/encounter.types";
import { Item } from "../../encounter/types/item.types";
import Bag from "../../bag/components/Bag";
import { IPokemon } from "../../pokemon/pokemon.types";
import PartyPokemon from "../../pokemon/components/PartyPokemon";
import GameModals from "./GameModals";

const Container = styled(Div100vh)`
  position: relative;
`;

const ScrollableBoardArea = styled(Div100vh)`
  display: grid;
  grid-template-areas: "game-board";
  width: calc(var(--vw, 1vw) * 100);
  overflow: scroll;
`;

const TopArea = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  padding: 2px;
  padding-bottom: 0;
  width: calc(100% - 4px);
  z-index: ${Z_INDEX.TILE + 1};

  .stats {
    margin: 5px;
  }
`;

const TopMessage = styled(StageInformation)`
  padding: 10px;
  border: 2px solid black;
  background-color: white;
  box-sizing: border-box;
`;

const ExpandedBoardArea = styled.div`
  position: relative;
  grid-area: game-board;
`;

const StyledPartyPokemon = styled(PartyPokemon)`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
  left: 20px;
  bottom: 60px;
`;

const ZoomButtons = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;

  button {
    font-size: 16px;
    height: 30px;
    width: 30px;
  }
`;

const StyledBag = styled(Bag)`
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 100px;
  justify-items: center;
  align-items: end;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10%;

  .items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;
    align-content: flex-end;
    justify-content: space-around;
  }
`;

const StyledBagItem = styled.img`
  max-height: 42px;
  max-width: 42px;
  background-color: rgba(255, 255, 255, 0.2);
`;

const StyledActionButtons = styled(ActionButtons)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 90%;
  width: 90%;
  padding: 10px;
`;

export interface Axis {
  min: number;
  max: number;
  gap?: React.CSSProperties["columnGap"];
}

const GAP_SIZE_PX = 5;
const GAP_SIZE = `${GAP_SIZE_PX}px`;
const TILE_SIZE_PX = 250;
const TILE_SIZE = `${TILE_SIZE_PX}px`;

const X_AXIS: Axis = { min: -8, max: 8, gap: GAP_SIZE };
const Y_AXIS: Axis = { min: -8, max: 8, gap: GAP_SIZE };

const xCount = X_AXIS.max - X_AXIS.min + 1;
const yCount = Y_AXIS.max - Y_AXIS.min + 1;

const calculateXSize = (cellCount: number): string =>
  `calc(calc(${TILE_SIZE} * ${cellCount}) + calc(${cellCount - 1} * ${
    X_AXIS.gap
  }))`;

const calculateYSize = (cellCount: number): string =>
  `calc(calc(${TILE_SIZE} * ${cellCount}) + calc(${cellCount - 1} * ${
    Y_AXIS.gap
  }))`;

const width = calculateXSize(xCount);
const height = calculateYSize(yCount);

export interface GameHandlers {
  onEventCompletion(event: EventEncounter): void;
  onForageAccept(): void;
  onForageSkip(): void;
  onItemDraw(): void;
  onItemDrop(droppedItem: Item): void;
  onItemSingleUse(item: Item): void;
  onItemSkip(): void;
  onItemTake(takenItem: Item, droppedItem?: Item): void;
  onMovementSelect(movement: PlayerMovement): void;
  onOpponentBattleAccept(enemyPokemon: number): void;
  onOpponentBattleFlee(): void;
  onPlacementTileAffix(): void;
  onPlacementTileRotate(): void;
  onRocketAmbushTrigger(coords: Coordinate, dir: Direction): void;
  onWildPokemonCatch(caughtPokemon: IPokemon, releasedPokemon?: IPokemon): void;
  onWildPokemonDraw(): void;
  onWildPokemonSkip(): void;
}

function GameOngoing({
  G,
  ctx,
  moves,
}: Omit<BoardProps<GameState>, "moves"> & { moves: Moves }): JSX.Element {
  const isRocketAttackDue = selectIsRocketAttackDue(G);

  useEffect(() => {
    if (G.placement?.data && !G.placement.state?.isFlippedUp) {
      setTimeout(() => {
        moves.flipPlacementTile();
      }, 300);
    }
  });

  useEffect(() => {
    if (
      G.turnStage === TurnStage.FORAGING &&
      // possibleMovements.every(({ existingTile }) => !!existingTile) &&
      isRocketAttackDue
    ) {
      moves.prepareForRocketAmbush();
    }
  });

  const { scrollTarget, scrollParent } = useInitialScroll<
    HTMLImageElement,
    HTMLDivElement
  >();

  const [scaleFactor, setScaleFactor] = useState(() => {
    // https://stackoverflow.com/questions/1248081/how-to-get-the-browser-viewport-dimensions
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    const tileWidthForJustOverTwo = vw / 2.5;

    const desiredTileWidth = Math.min(tileWidthForJustOverTwo, TILE_SIZE_PX);

    return desiredTileWidth / TILE_SIZE_PX;
  });

  const [open, setModalOpen] = useState<
    Partial<Record<"bag" | "encounter" | "item" | "wild", boolean>> & {
      party?: number;
    }
  >({});

  useEffect(() => {
    if (
      G.turnStage === TurnStage.ITEM_DISCOVERY &&
      G.encounterCards.itemDiscovery
    ) {
      setModalOpen({ item: true });
    } else if (G.turnStage === TurnStage.WILD_POKEMON && G.wildPokemon.active) {
      setModalOpen({ wild: true });
    }
  }, [
    G.turnStage,
    G.encounterCards.active,
    G.encounterCards.itemDiscovery,
    G.wildPokemon.active,
  ]);

  const handlers: GameHandlers = {
    onEventCompletion: (encounter) => moves.processEvent(encounter),
    onForageAccept: () => moves.forageForBerries(),
    onForageSkip: () => moves.skipForaging(),
    onItemDraw: () => moves.drawItemDiscoveryEncounter(),
    onItemDrop: (item) => moves.dropItem(item),
    onItemSingleUse: (item) => moves.consumeSingleUseItem(item),
    onItemSkip: () => moves.skipItemDiscovery(),
    onItemTake: (takenItem, droppedItem) =>
      moves.takeItem(takenItem, droppedItem),
    onOpponentBattleAccept: (enemyPokemon) => moves.fightOpponent(enemyPokemon),
    onOpponentBattleFlee: () => moves.fleeOpponent(),
    onMovementSelect: (movement) => moves.chooseMovement(movement),
    onPlacementTileAffix: () => moves.affixPlacementTile(),
    onPlacementTileRotate: () => moves.rotatePlacementTile(),
    onRocketAmbushTrigger: (coords, direction) =>
      moves.triggerRocketAmbush(coords, direction),
    onWildPokemonCatch: (caught, released) =>
      moves.catchPokemon(caught, released),
    onWildPokemonDraw: () => moves.encounterWildPokemon(),
    onWildPokemonSkip: () => moves.skipWildPokemon(),
  };

  return (
    <Container>
      <ScrollableBoardArea className="scrollable-board-area" ref={scrollParent}>
        <TopArea>
          <VitalStats
            className="stats"
            attack={selectPlayerComputedAttack(G)}
            hp={selectPlayerComputedHealth(G)}
          />
          <Timer
            className="stats"
            hoursLeft={G.hoursLeft}
            cardsLeft={selectRemainingEncounterCardsCount(G)}
          />
          <TopMessage
            handlers={handlers}
            onEncounterCardView={() => setModalOpen({ encounter: true })}
            onItemDiscoveryView={() => setModalOpen({ item: true })}
            onWildPokemonView={() => setModalOpen({ wild: true })}
            style={{ zIndex: Z_INDEX.TILE + 2 }}
            turnStage={G.turnStage}
          />
        </TopArea>
        <ExpandedBoardArea
          style={{
            width,
            height,
            transform: `scale(${scaleFactor})`,
          }}
        >
          <BoardTiles
            handlers={handlers}
            isPlacementLegal={selectHasLegalPlacement(G)}
            placedTiles={G.placedTiles}
            placement={G.placement}
            playerCoordinates={selectPlayerCoordinates(G)}
            possibleMovements={selectPossiblePlayerMovements(G)}
            possibleRocketAttack={selectCurrentPlayerTileRocketRoutes(G)}
            renderOnPlayerTile={() => (
              <StyledActionButtons
                handlers={handlers}
                onEncounterCardView={() => setModalOpen({ encounter: true })}
                onItemDiscoveryView={() => {
                  setModalOpen({ item: true });
                }}
                onWildPokemonView={() => setModalOpen({ wild: true })}
                turnStage={G.turnStage}
              />
            )}
            tileSize={TILE_SIZE}
            turnStage={G.turnStage}
            xAxis={X_AXIS}
            yAxis={Y_AXIS}
          />
          <Player
            ref={scrollTarget}
            style={{
              position: "absolute",
              left: `calc(${calculateXSize(
                selectPlayerCoordinates(G).x - X_AXIS.min + 1
              )} - 60px - 15px)`,
              bottom: `calc(${calculateYSize(
                selectPlayerCoordinates(G).y - Y_AXIS.min
              )} + 60px - 15px)`,
              height: "60px",
              zIndex: Z_INDEX.TILE + 1,
            }}
          />
        </ExpandedBoardArea>
      </ScrollableBoardArea>
      <GameModals
        {...{ handlers, open }}
        encounterCard={selectActiveDrawnEncounterCard(G)}
        hoursLeft={selectGameHoursLeft(G)}
        itemDiscoveryCard={selectItemDiscoveryCard(G)}
        onCloseBag={() => setModalOpen({ bag: false })}
        onCloseEncounter={() => setModalOpen({ encounter: false })}
        onCloseItemDiscovery={() => setModalOpen({ item: false })}
        onClosePartyPokemon={() => setModalOpen({})}
        onCloseWildPokemon={() => setModalOpen({ wild: false })}
        onPartyPokemonSelect={(n) => setModalOpen({ party: n })}
        turnStage={G.turnStage}
        partyPokemonList={selectPartyPokemon(G)}
        playerItems={selectPlayerItemsList(G)}
        wildPokemon={selectActiveWildPokemon(G)}
      />
      <StyledPartyPokemon
        onPokemonClick={(pokemon, index) => setModalOpen({ party: index })}
        pokemonList={selectPartyPokemon(G)}
      />
      <ZoomButtons>
        <button onClick={() => setScaleFactor((prev) => prev * 1.1)}>+</button>
        <button onClick={() => setScaleFactor((prev) => prev / 1.1)}>-</button>
      </ZoomButtons>
      <StyledBag
        bagWidth="80px"
        variant={open.bag ? "open" : "closed"}
        onClick={() => setModalOpen({ bag: true })}
      >
        <div className="items">
          {selectPlayerItemsList(G).map((item, idx) => (
            <StyledBagItem
              key={item.type}
              alt={item.type}
              src={item.imageSrc}
            />
          ))}
        </div>
      </StyledBag>
    </Container>
  );
}

export default GameOngoing;
