import { Ctx } from "boardgame.io";
import { cloneDeep, set } from "lodash";
import { EventEncounter } from "../encounter/types/encounter.types";
import { Item, ItemType } from "../encounter/types/item.types";
import { WILD_POKEMON } from "../pokemon/data";
import { IPokemon } from "../pokemon/pokemon.types";
import { SAFARI_ZONE_TILES } from "../tiles/data";
import {
  Direction,
  isSafariZoneTile,
  isTownTile,
  MapTile,
  TileConnectionPath,
} from "../tiles/tiles.types";
import {
  TurnStage,
  GameState,
  PlayerMovement,
  Coordinate,
  XYCoordinateString,
} from "./game.types";
import {
  selectCurrentPlayerTile,
  selectGetTileAtCoordinate,
  selectPartyPokemon,
  selectPlayerComputedAttack,
} from "./selectors/game.selectors";
import { translate } from "./utils/direction-utils";
import { NORMAL_WALKABLE_PATHS } from "./utils/getPossibleMovements";
import { rotateHinges } from "./utils/isConnectedByPath";

type MoveDefinition<RestParams extends unknown[] = []> = (
  game: GameState,
  ctx: Ctx,
  ...rest: RestParams
) => void;

const addPokemonToParty: MoveDefinition<[IPokemon, IPokemon?]> = (
  game,
  ctx,
  caughtPokemon,
  releasedPokemon
) => {
  if (releasedPokemon) {
    delete game.player.pokemon[releasedPokemon.species];
  }

  game.player.pokemon[caughtPokemon.species] = true;
};

const advanceStage: MoveDefinition = (game, ctx) => {
  switch (game.turnStage) {
    case TurnStage.MOVEMENT_CHOICE:
      game.turnStage = TurnStage.TILE_PLACEMENT;
      break;
    case TurnStage.TILE_PLACEMENT:
      game.turnStage = TurnStage.ENCOUNTER_CARD;
      break;
    case TurnStage.ENCOUNTER_CARD:
    case TurnStage.ITEM_DISCOVERY:
    case TurnStage.WILD_POKEMON:
      game.turnStage = TurnStage.FORAGING;
      break;
  }
};

const advanceToEncounter: MoveDefinition = (game, ctx) => {
  game.turnStage = TurnStage.ENCOUNTER_CARD;
  drawEncounterCard(game, ctx);
};

export const affixPlacementTile: MoveDefinition = (game, ctx) => {
  if (!game.placement) {
    throw new Error("Can't place non-existent tile");
  }
  const { data, state, to } = game.placement;
  delete game.placement;
  game.placedTiles[`${to.x},${to.y}`] = {
    ...cloneDeep({ data, state }),
    coordinates: to,
  };
  game.player.coordinates = to;

  const rotatedConnections = rotateHinges(data.connections, state?.rotate ?? 0);

  for (const [direction, connection] of Object.entries(rotatedConnections) as [
    Direction,
    TileConnectionPath
  ][]) {
    if (NORMAL_WALKABLE_PATHS.includes(connection)) {
      const connectingCoords = translate(to, direction);
      const coordStr: XYCoordinateString = `${connectingCoords.x},${connectingCoords.y}`;
      if (!game.placedTiles[coordStr]) {
        game.unexploredPaths++;
      }
    }
  }

  advanceToEncounter(game, ctx);
};

export const catchPokemon: MoveDefinition<[IPokemon, IPokemon?]> = (
  game,
  ctx,
  caughtPokemon,
  releasedPokemon
) => {
  addPokemonToParty(game, ctx, caughtPokemon, releasedPokemon);
  advanceStage(game, ctx);
};

export const chooseMovement: MoveDefinition<[PlayerMovement]> = (
  game,
  ctx,
  movement
) => {
  if (!movement.existingTile) {
    game.unexploredPaths--; // closes off a path
  }

  const extantDestinationTile = selectGetTileAtCoordinate(game)(movement.to);
  game.lastMovement = movement;

  if (!extantDestinationTile) {
    const currentTile = selectCurrentPlayerTile(game);
    let nextTile: MapTile | undefined;

    if (movement.connection === TileConnectionPath.ENTER_SAFARI_ZONE) {
      nextTile = SAFARI_ZONE_TILES.ENTRANCE;
    } else if (movement.connection === TileConnectionPath.PAVED_PATH) {
      nextTile = game.tileStacks.town.shift();
    } else if (movement.connection === TileConnectionPath.SANDY_PATH) {
      nextTile = game.tileStacks.safariZone.shift();
    } else if (isTownTile(currentTile.data)) {
      nextTile = game.tileStacks.town.shift();
    } else if (isSafariZoneTile(currentTile.data)) {
      nextTile = game.tileStacks.safariZone.shift();
    }

    if (!nextTile) {
      throw new Error("didn't set a tile properly");
    }

    game.placement = {
      ...game.placement,
      data: nextTile,
      to: movement.to,
    };

    game.turnStage = TurnStage.TILE_PLACEMENT;
    return;
  }

  game.player.coordinates = movement.to;
  advanceToEncounter(game, ctx);
};

const completeEncounterCard: MoveDefinition = (game, ctx) => {
  const cardToDiscard =
    game.encounterCards.active ?? game.encounterCards.itemDiscovery;
  if (!cardToDiscard) {
    throw new Error("No card to complete");
  }
  game.encounterCards.discard.push(cardToDiscard);
  delete game.encounterCards.active;
  delete game.encounterCards.itemDiscovery;
  advanceStage(game, ctx);
};

const completeTurn: MoveDefinition = (game, ctx) => {
  ctx.events?.endTurn();
  game.turnStage = TurnStage.MOVEMENT_CHOICE;
};

export const consumeSingleUseItem: MoveDefinition<[Item]> = (
  game,
  ctx,
  item
) => {
  switch (item.type) {
    case ItemType.HYPER_POTION:
      game.player.stats.hp += 2;
      break;
    case ItemType.SITRUS_BERRY:
      game.player.stats.hp += 1;
      break;
  }

  dropItem(game, ctx, item);
};

export const drawEncounterCard: MoveDefinition = (game, ctx) => {
  if (game.encounterCards.deck.length >= 1) {
    const nextEncounterCard = game.encounterCards.deck.shift();
    game.encounterCards.active = nextEncounterCard;
  } else {
    timePassses(game, ctx);
    drawEncounterCard(game, ctx);
  }
};

export const dropItem: MoveDefinition<[Item]> = (game, ctx, item) => {
  delete game.player.items[item.type];
};

export const drawItemDiscoveryEncounter: MoveDefinition = (game, ctx) => {
  if (game.encounterCards.active) {
    game.encounterCards.discard.push(game.encounterCards.active);
    delete game.encounterCards.active;
  }

  if (game.encounterCards.deck.length >= 1) {
    const nextEncounterCard = game.encounterCards.deck.shift();
    game.encounterCards.itemDiscovery = nextEncounterCard;
    game.turnStage = TurnStage.ITEM_DISCOVERY;
  } else {
    timePassses(game, ctx);
    drawItemDiscoveryEncounter(game, ctx);
  }
};

export const encounterWildPokemon: MoveDefinition = (game, ctx) => {
  const party = selectPartyPokemon(game);
  const possibleWildPokemon = Object.values(WILD_POKEMON).filter(
    ({ species: wildSpecies }) =>
      !party.some(({ species: partySpecies }) => partySpecies === wildSpecies)
  );
  // should be okay to assert - API feature
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const randomWildPokemon = ctx.random!.Shuffle(possibleWildPokemon)[0];
  game.wildPokemon.active = randomWildPokemon;
  game.turnStage = TurnStage.WILD_POKEMON;
};

export const fightOpponent: MoveDefinition<[number]> = (
  game,
  ctx,
  enemyPokemon: number
) => {
  game.player.stats.hp -= Math.max(
    0,
    enemyPokemon - selectPlayerComputedAttack(game)
  );
  completeEncounterCard(game, ctx);
};

export const fleeOpponent: MoveDefinition = (game, ctx) => {
  game.player.stats.hp -= 1;
  game.turnStage = TurnStage.OPPONENT_FLEE;
};

export const flipPlacementTile: MoveDefinition = (game, ctx) => {
  set(game, "placement.state.isFlippedUp", true);
};

export const forageForBerries: MoveDefinition = (game: GameState, ctx: Ctx) => {
  const discardCard = game.encounterCards.deck.shift();
  if (discardCard) {
    game.player.stats.hp += 3;
    game.encounterCards.discard.push(discardCard);
    completeTurn(game, ctx);
  } else {
    timePassses(game, ctx);
    forageForBerries(game, ctx);
  }
};

export const prepareForRocketAmbush: MoveDefinition = (game, ctx) => {
  game.turnStage = TurnStage.ROCKET_ATTACK_ROUTE_CHOICE;
};

export const processEvent: MoveDefinition<[EventEncounter]> = (
  game,
  ctx,
  encounter
) => {
  game.player.stats.hp += encounter.increment.hp ?? 0;
  game.player.stats.attack += encounter.increment.attack ?? 0;
  completeEncounterCard(game, ctx);
};

export const rotatePlacementTile: MoveDefinition = (game, ctx) => {
  if (typeof game.placement?.state?.rotate === "number") {
    game.placement.state.rotate += 90;
  } else {
    set(game, "placement.state.rotate", 90);
  }
};

export const skipForaging: MoveDefinition = (game, ctx) => {
  completeTurn(game, ctx);
};

export const skipItemDiscovery: MoveDefinition = (game, ctx) => {
  completeEncounterCard(game, ctx);
};

export const skipWildPokemon: MoveDefinition = (game, ctx) => {
  completeEncounterCard(game, ctx);
};

export const takeItem: MoveDefinition<[Item, Item?]> = (
  game,
  ctx,
  acquiredItem,
  droppedItem
) => {
  game.player.items[acquiredItem.type] = true;
  droppedItem && delete game.player.items[droppedItem.type];
  advanceStage(game, ctx);
};

export const triggerRocketAmbush: MoveDefinition<[Coordinate, Direction]> = (
  game,
  ctx,
  coord,
  direction
) => {
  game.placedTiles[`${coord.x},${coord.y}`].rocketRoutes = {
    ...game.placedTiles[`${coord.x},${coord.y}`].rocketRoutes,
    [direction]: true,
  };
  game.unexploredPaths++; // opens up a new route
  completeTurn(game, ctx);
};

const timePassses: MoveDefinition = (game, ctx) => {
  // random API should exist
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  game.encounterCards.deck = ctx.random!.Shuffle(game.encounterCards.discard);
  game.encounterCards.discard = [];
  game.hoursLeft--;
};
