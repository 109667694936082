import { Button, Modal, Space, Title } from "@mantine/core";
import BagContents from "./BagContents";
import { Item } from "../../encounter/types/item.types";
import { GameHandlers } from "../../game/components/GameOngoing";

interface Props {
  handlers: GameHandlers;
  items: Item[];
  isOpen?: boolean;
  onClose?(): void;
  title?: string;
}

function BagModal({
  handlers,
  items,
  isOpen,
  onClose,
  title = "Bag items",
}: Props): JSX.Element {
  return (
    <Modal
      opened={!!isOpen}
      onClose={() => onClose && onClose()}
      title={<Title order={3}>{title}</Title>}
    >
      <BagContents {...{ handlers, items }} />
      <Space />
      <Button
        compact
        fullWidth
        onClick={() => onClose && onClose()}
        variant="outline"
      >
        Back to map
      </Button>
    </Modal>
  );
}

export default BagModal;
