import styled from "styled-components";
import { animated, SpringValue, useSpring } from "react-spring";
import { forwardRef } from "react";
import { Z_INDEX } from "../../../utils/constants";

export interface TileProps {
  active?: boolean;
  className?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
  backSrc: string;
  frontSrc: string;
  hotspots?: {
    onClickCenter?(): void;
    onClickCorner?(): void;
  };
  children?: React.ReactNode;
  isFlippedUp?: boolean;
  onClick?(): void;
  onClickFront?(): void;
  onClickBack?(): void;
  // onAnimationComplete?(isFlippedUp: boolean): void;
  rotate?: number;
  size?: React.CSSProperties["width"];
}

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-areas: "content";

  img {
    border-radius: 12.5%;
    grid-area: content;
  }

  div.tile-children {
    grid-area: content;
  }

  div.hotspots {
    display: grid;
    grid-area: content;
    grid-template-areas: "hotspots";
  }

  span {
    grid-area: hotspots;
  }
`;

function TileWithRef(
  {
    active,
    className,
    children,
    disabled,
    style,
    backSrc,
    frontSrc,
    onClick,
    hotspots,
    onClickFront,
    onClickBack,
    // onAnimationComplete,
    isFlippedUp,
    rotate: rotation = 0,
    size,
  }: TileProps,
  ref: React.ForwardedRef<HTMLDivElement>
): JSX.Element {
  const { transform, opacity, rotate, filter } = useSpring({
    opacity: isFlippedUp ? 1 : 0,
    transform: `rotateY(${isFlippedUp ? 0 : 180}deg)`,
    rotate: rotation,
    filter: disabled ? "grayscale(100%)" : "grayscale(0%)",
  });

  const activeStyling: React.CSSProperties = active
    ? {
        boxSizing: "border-box",
        border: "5px solid mediumorchid",
        borderRadius: "12.5%",
      }
    : {};

  const front = (
    <animated.img
      onClick={() => onClickFront && onClickFront()}
      style={{
        opacity: opacity,
        transform,
        rotate,
        filter,
        // display: opacity.to((v) => (v === 0 ? "none" : "block")),
        width: size,
        height: size,
        boxShadow: "0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1)",
        ...activeStyling,
      }}
      src={frontSrc}
    ></animated.img>
  );

  const back = (
    <animated.img
      onClick={() => onClickBack && onClickBack()}
      style={{
        opacity: opacity.to((v) => 1 - v),
        transform,
        rotateY: "180deg",
        // display: opacity.to((v) => (v === 1 ? "none" : "block")),
        width: size,
        height: size,
        ...activeStyling,
      }}
      src={backSrc}
    ></animated.img>
  );

  return (
    <Container
      className={className}
      ref={ref}
      style={{
        ...style,
        width: size,
        height: size,
        zIndex: Z_INDEX.TILE,
      }}
      onClick={onClick}
    >
      {front}
      {back}
      {hotspots && (
        <TileHotspots
          disableCenter={disabled}
          {...{ opacity, rotate, transform, ...hotspots }}
        />
      )}
      {children && <div className="tile-children">{children}</div>}
    </Container>
  );
}

interface TileHotspotsProps {
  disableCenter?: boolean;
  disableCorner?: boolean;
  onClickCenter?(): void;
  onClickCorner?(): void;
  opacity: SpringValue<number>;
  rotate: SpringValue<number>;
  transform: SpringValue<string>;
}

function TileHotspots({
  disableCenter,
  disableCorner,
  onClickCenter,
  onClickCorner,
  opacity,
  rotate,
  transform,
}: TileHotspotsProps): JSX.Element {
  const { centerColor } = useSpring({
    centerColor: disableCenter ? "red" : "lawngreen",
  });

  return (
    <animated.div
      className="hotspots"
      style={{
        opacity,
        rotate,
      }}
    >
      <animated.span
        className="center-hotspot"
        onClick={() => {
          if (disableCenter) {
            window.alert("Can't place the tile like this - no path matches up");
          } else {
            onClickCenter && onClickCenter();
          }
        }}
        style={{
          height: "60px",
          width: "60px",
          borderRadius: "20%",
          backgroundColor: centerColor,
          opacity: opacity.to((v) => v * 0.7),
          transform,
          justifySelf: "center",
          alignSelf: "center",
          zIndex: Z_INDEX.TILE + 1,
        }}
      />
      <animated.span
        className="corner-hotspot"
        onClick={() => onClickCorner && onClickCorner()}
        style={{
          height: "60px",
          width: "60px",
          borderRadius: "50% 20% 20% 20%",
          backgroundColor: disableCorner ? "red" : "cyan",
          opacity: opacity.to((v) => v / 2),
          transform,
          justifySelf: "start",
          alignSelf: "start",
          zIndex: Z_INDEX.TILE + 1,
        }}
      />
      <animated.span
        className="corner-hotspot"
        onClick={() => onClickCorner && onClickCorner()}
        style={{
          height: "60px",
          width: "60px",
          borderRadius: "20% 50% 20% 20%",
          backgroundColor: disableCorner ? "red" : "cyan",
          opacity: opacity.to((v) => v / 2),
          transform,
          justifySelf: "end",
          alignSelf: "start",
          zIndex: Z_INDEX.TILE + 1,
        }}
      />
      <animated.span
        className="corner-hotspot"
        onClick={() => onClickCorner && onClickCorner()}
        style={{
          height: "60px",
          width: "60px",
          borderRadius: "20% 20% 50% 20%",
          backgroundColor: disableCorner ? "red" : "cyan",
          opacity: opacity.to((v) => v / 2),
          transform,
          justifySelf: "end",
          alignSelf: "end",
          zIndex: Z_INDEX.TILE + 1,
        }}
      />
      <animated.span
        className="corner-hotspot"
        onClick={() => onClickCorner && onClickCorner()}
        style={{
          height: "60px",
          width: "60px",
          borderRadius: "20% 20% 20% 50%",
          backgroundColor: disableCorner ? "red" : "cyan",
          opacity: opacity.to((v) => v / 2),
          transform,
          justifySelf: "start",
          alignSelf: "end",
          zIndex: Z_INDEX.TILE + 1,
        }}
      />
    </animated.div>
  );
}
const Tile = forwardRef(TileWithRef);

export default Tile;
