import { Client } from "boardgame.io/react";
import TeamRocketInMyPocket from "../game";
import GameOngoing from "./GameOngoing";

const Board = Client({
  game: TeamRocketInMyPocket,
  board: GameOngoing,
  debug: process.env.REACT_APP_DEBUG_OFF !== "true",
});

export default Board;
