import { Z_INDEX } from "../../../utils/constants";
import { Direction } from "../../tiles/tiles.types";
import { Coordinate } from "../game.types";
import { GameHandlers } from "./GameOngoing";

interface Props extends Pick<GameHandlers, "onRocketAmbushTrigger"> {
  gridAdjustX(n: number): number;
  gridAdjustY(n: number): number;
  playerCoordinates: Coordinate;
  possibleRocketAttack: Partial<Record<Direction, boolean>>;
}

function RocketAttack({
  gridAdjustX,
  gridAdjustY,
  onRocketAmbushTrigger,
  playerCoordinates,
  possibleRocketAttack,
}: Props): JSX.Element {
  return (
    <>
      {possibleRocketAttack.left && (
        <button
          key="rocket-left"
          onClick={() =>
            onRocketAmbushTrigger &&
            onRocketAmbushTrigger(playerCoordinates, Direction.LEFT)
          }
          style={{
            gridColumn: gridAdjustX(playerCoordinates.x),
            gridRow: gridAdjustY(playerCoordinates.y),
            justifySelf: "start",
            alignSelf: "center",
            zIndex: Z_INDEX.TILE + 1,
          }}
        >
          ⚔️
        </button>
      )}
      {possibleRocketAttack.right && (
        <button
          key="rocket-right"
          onClick={() =>
            onRocketAmbushTrigger &&
            onRocketAmbushTrigger(playerCoordinates, Direction.RIGHT)
          }
          style={{
            gridColumn: gridAdjustX(playerCoordinates.x),
            gridRow: gridAdjustY(playerCoordinates.y),
            justifySelf: "end",
            alignSelf: "center",
            zIndex: Z_INDEX.TILE + 1,
          }}
        >
          ⚔️
        </button>
      )}
      {possibleRocketAttack.up && (
        <button
          key="rocket-up"
          onClick={() =>
            onRocketAmbushTrigger &&
            onRocketAmbushTrigger(playerCoordinates, Direction.UP)
          }
          style={{
            gridColumn: gridAdjustX(playerCoordinates.x),
            gridRow: gridAdjustY(playerCoordinates.y),
            justifySelf: "center",
            alignSelf: "start",
            zIndex: Z_INDEX.TILE + 1,
          }}
        >
          ⚔️
        </button>
      )}
      {possibleRocketAttack.down && (
        <button
          key="rocket-down"
          onClick={() =>
            onRocketAmbushTrigger &&
            onRocketAmbushTrigger(playerCoordinates, Direction.DOWN)
          }
          style={{
            gridColumn: gridAdjustX(playerCoordinates.x),
            gridRow: gridAdjustY(playerCoordinates.y),
            justifySelf: "center",
            alignSelf: "end",
            zIndex: Z_INDEX.TILE + 1,
          }}
        >
          ⚔️
        </button>
      )}
    </>
  );
}

export default RocketAttack;
