export enum PokemonSpecies {
  ABRA = "Abra",
  CHANSEY = "Chansey",
  CHARIZARD = "Charizard",
  ELECTRODE = "Electrode",
  KANGASKHAN = "Kangaskhan",
  LAPRAS = "Lapras",
  PIKACHU = "Pikachu",
}

export enum PokemonType {
  ELECTRIC = "Electric",
  FIRE = "Fire",
  FLYING = "Flying",
  ICE = "Ice",
  NORMAL = "Normal",
  PSYCHIC = "Psychic",
  WATER = "Water",
}

export enum PokemonVariant {
  ABRA_FORESIGHT = "Foresight",
  ABRA_TELEPORT = "Teleport",
  CHARIZARD_FIRE_BLAST = "Fire Blast",
  CHARIZARD_FLY = "Fly",
}

export interface IPokemon {
  species: PokemonSpecies;
  type: [PokemonType] | [PokemonType, PokemonType];
  category: string;
  pokedex: {
    number: number;
    entry: string;
  };
  height: { metres: number; feet: number; inches: number };
  weight: { kg: number; lbs: number };
  src: {
    box: string;
    png: string;
    gif: string;
  };
  variants?: PokemonVariant[];
}
