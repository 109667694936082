import { Button, Divider, Text, Title } from "@mantine/core";
import { useEffect } from "react";
import { useRef } from "react";
import styled from "styled-components";
import { GameHandlers } from "../../game/components/GameOngoing";
import cardBackgroundSrc from "../assets/pikachu-background.jpg";
import pokeballSrc from "../assets/pokeball.png";
import {
  Encounter,
  EncounterHour,
  EncounterType,
  IEncounterCard,
} from "../types/encounter.types";

interface EncounterCardProps {
  className?: string;
  handlers: GameHandlers;
  style?: React.CSSProperties;
  active?: EncounterHour | "item";
  card: IEncounterCard;
}

const Container = styled.div`
  background-image: url("${cardBackgroundSrc}");
  background-position: center;
  border: 1px solid black;
  display: grid;
  max-height: 60vh;
  max-height: calc(var(--vh, 1vh) * 60);
  overflow-y: scroll;
  padding: 10px;
  row-gap: 10px;
  grid-template-areas:
    "encounter-3"
    "encounter-2"
    "encounter-1"
    "divider"
    "item";

  section {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px;
  }

  section.active {
    background-color: rgba(0, 255, 255, 0.7);
    border: 1px solid cyan;
  }

  .encounter-timer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Item = styled.section`
  grid-area: item;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  img {
    max-height: 32px;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 10px;
`;

function EncounterCard({
  className,
  handlers,
  style,
  card,
  active: activeSection,
}: EncounterCardProps): JSX.Element {
  const activeSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeSectionRef.current) {
      activeSectionRef.current.scrollIntoView();
    }
  }, [card]);

  return (
    <Container {...{ className, style }}>
      {Object.entries(card.tMinus).map(([encounterHour, encounter]) => {
        const hour = parseInt(encounterHour);
        const active = hour === activeSection;

        return (
          <section
            key={hour}
            ref={active ? activeSectionRef : undefined}
            className={`encounter${active ? " active" : ""}`}
            style={{ gridArea: `encounter-${hour}` }}
          >
            <div className="encounter-timer">
              <Text size="xs" weight="bold">
                T-{encounterHour}
              </Text>
              <Text size="xs">{Array(hour).fill("⏰").join("")}</Text>
            </div>
            <EncounterBody
              {...{
                active,
                encounter,
                handlers,
              }}
            />
          </section>
        );
      })}
      <Divider className="divider" />
      <Item
        className={activeSection === "item" ? "active" : undefined}
        ref={activeSection === "item" ? activeSectionRef : undefined}
      >
        <Text size="xs" weight="bold">
          Item
        </Text>
        <div className="title">
          <img alt={card.item.type} src={card.item.imageSrc} />
          <Title order={5}>{card.item.type}</Title>
        </div>
        <div>
          {card.item.descriptionHeader && (
            <>
              <Text component="span" weight="bold" size="sm">
                {card.item.descriptionHeader}
              </Text>
              <Text component="span" size="sm">
                {" "}
                -{" "}
              </Text>
            </>
          )}
          <Text component="span" size="sm">
            {card.item.descriptionBody}
          </Text>
          <Text component="span" size="xs">
            {" (" + card.item.persistence + ")"}
          </Text>
        </div>
        {activeSection === "item" && (
          <Buttons>
            <Button compact onClick={() => handlers.onItemTake(card.item)}>
              Take
            </Button>
            <Button
              compact
              onClick={() => handlers.onItemSkip()}
              variant="light"
              color="red"
            >
              Leave
            </Button>
          </Buttons>
        )}
      </Item>
    </Container>
  );
}

interface EncounterBodyProps {
  className?: string;
  handlers: GameHandlers;
  active?: boolean;
  encounter: Encounter;
  style?: React.CSSProperties;
}

function EncounterBody({
  active,
  className,
  encounter,
  handlers,
}: EncounterBodyProps): JSX.Element {
  switch (encounter.type) {
    case EncounterType.GENERAL_EVENT:
      return (
        <>
          <Text size="sm">
            {encounter.text}
            {encounter.increment.attack &&
              ` [${encounter.increment.attack > 0 ? "+" : ""}${
                encounter.increment.attack
              } ATK]`}
            {encounter.increment.hp &&
              ` [${encounter.increment.hp > 0 ? "+" : ""}${
                encounter.increment.hp
              } HP]`}
          </Text>
          {active && (
            <Buttons>
              <Button
                compact
                onClick={() =>
                  handlers.onEventCompletion &&
                  handlers.onEventCompletion(encounter)
                }
              >
                Complete event
              </Button>
            </Buttons>
          )}
        </>
      );

    case EncounterType.ITEM_FOUND:
      return (
        <>
          <Title order={5}>Item found!</Title>
          <Text size="sm">
            You can draw an Encounter card and optionally claim its item.
          </Text>
          {active && (
            <Buttons>
              <Button
                compact
                onClick={() => handlers.onItemDraw && handlers.onItemDraw()}
              >
                Draw
              </Button>
              <Button
                compact
                onClick={() => handlers.onItemSkip && handlers.onItemSkip()}
                variant="light"
                color="red"
              >
                Skip
              </Button>
            </Buttons>
          )}
        </>
      );

    case EncounterType.ROCKET_BATTLE:
      return (
        <>
          <Title order={5}>Rocket Grunt wants to battle!</Title>
          <Text size="sm" style={{ display: "flex", alignItems: "center" }}>
            Pokémon ({encounter.enemyPokemon}):{" "}
            {Array(encounter.enemyPokemon)
              .fill("p")
              .map((_, idx) => (
                <img
                  key={idx}
                  alt="pokeball"
                  src={pokeballSrc}
                  style={{ height: "18px" }}
                />
              ))}
          </Text>
          {active && (
            <Buttons>
              <Button
                compact
                onClick={() =>
                  handlers.onOpponentBattleAccept &&
                  handlers.onOpponentBattleAccept(encounter.enemyPokemon)
                }
              >
                Battle
              </Button>
              <Button
                compact
                onClick={() =>
                  handlers.onOpponentBattleFlee &&
                  handlers.onOpponentBattleFlee()
                }
                variant="light"
                color="red"
              >
                Flee
              </Button>
            </Buttons>
          )}
        </>
      );

    case EncounterType.WILD_POKEMON:
      return (
        <>
          <Title order={5}>A wild Pokémon appeared!</Title>
          <Text size="sm">
            You can draw a Pokémon card and optionally add/swap it into your
            party.
          </Text>
          {active && (
            <Buttons>
              <Button
                compact
                onClick={() =>
                  handlers.onWildPokemonDraw && handlers.onWildPokemonDraw()
                }
              >
                Draw
              </Button>
              <Button
                compact
                onClick={() =>
                  handlers.onWildPokemonSkip && handlers.onWildPokemonSkip()
                }
                variant="light"
                color="red"
              >
                Skip
              </Button>
            </Buttons>
          )}
        </>
      );
  }
}

export default EncounterCard;
