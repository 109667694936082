import { useEffect } from "react";

function useMobileViewport(): void {
  useEffect(() => {
    // https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html#comment-4634921967
    function setDocViewport() {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight / 100}px`
      );
      document.documentElement.style.setProperty(
        "--vw",
        `${window.innerWidth / 100}px`
      );
    }

    setDocViewport();

    window.addEventListener("resize", setDocViewport);
    window.addEventListener("orientationchange", setDocViewport);

    return function cleanup() {
      window.removeEventListener("resize", setDocViewport);
      window.removeEventListener("orientationchange", setDocViewport);
    };
  });
}

export default useMobileViewport;
