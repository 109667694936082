import { forwardRef } from "react";
import { animated, useSpring } from "react-spring";
// import useDimensions from "../../../hooks/useDimensions";
import { Z_INDEX } from "../../../utils/constants";
import playerSrc from "../assets/pikachu-3d.png";

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

// const DummyDiv = styled.div``;

function PlayerWithRef(
  { className, style = {} }: Props,
  ref: React.ForwardedRef<HTMLImageElement>
): JSX.Element {
  const styles = useSpring({
    ...style,
  });

  return (
    <animated.img
      ref={ref}
      className={className}
      src={playerSrc}
      style={{
        ...styles,
        zIndex: Z_INDEX.PLAYER,
        filter: "drop-shadow(5px 10px 15px black)",
      }}
    />
  );
}

const Player = forwardRef(PlayerWithRef);

export default Player;
