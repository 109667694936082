import { TileConnectionPath, safariZoneTile } from "../tiles.types";
import beachSrc from "../assets/safari/beach.png";
import berrySrc from "../assets/safari/berry-patch.png";
import elderTreeSrc from "../assets/safari/elder-tree.png";
import entranceSrc from "../assets/safari/entrance.png";
import lakeSrc from "../assets/safari/lake.png";
import pillarsSrc from "../assets/safari/rock-pillars.png";
import hideoutSrc from "../assets/safari/rocket-hideout.png";
import wellSrc from "../assets/safari/wishing-well.png";

export const BEACH = safariZoneTile({
  frontSrc: beachSrc,
  connections: {
    up: TileConnectionPath.SANDY_PATH,
    down: TileConnectionPath.WATER,
    left: TileConnectionPath.WATER,
    right: TileConnectionPath.CUTTABLE_HEDGE,
  },
});

export const BERRY_PATCH = safariZoneTile({
  frontSrc: berrySrc,
  connections: {
    up: TileConnectionPath.CUTTABLE_HEDGE,
    down: TileConnectionPath.SANDY_PATH,
    left: TileConnectionPath.SANDY_PATH,
    right: TileConnectionPath.SANDY_PATH,
  },
});

export const ELDER_TREE = safariZoneTile({
  frontSrc: elderTreeSrc,
  connections: {
    up: TileConnectionPath.SANDY_PATH,
    down: TileConnectionPath.TREES,
    left: TileConnectionPath.SANDY_PATH,
    right: TileConnectionPath.TREES,
  },
});

export const ENTRANCE = safariZoneTile({
  frontSrc: entranceSrc,
  connections: {
    up: TileConnectionPath.SANDY_PATH,
    down: TileConnectionPath.EXIT_SAFARI_ZONE,
    left: TileConnectionPath.TREES,
    right: TileConnectionPath.SANDY_PATH,
  },
});

export const LAKE = safariZoneTile({
  frontSrc: lakeSrc,
  connections: {
    up: TileConnectionPath.SANDY_PATH,
    down: TileConnectionPath.SANDY_PATH,
    left: TileConnectionPath.TREES,
    right: TileConnectionPath.FENCING,
  },
});

export const ROCK_PILLARS = safariZoneTile({
  frontSrc: pillarsSrc,
  connections: {
    up: TileConnectionPath.SANDY_PATH,
    down: TileConnectionPath.CUTTABLE_HEDGE,
    left: TileConnectionPath.SANDY_PATH,
    right: TileConnectionPath.CUTTABLE_HEDGE,
  },
});

export const ROCKET_HIDEOUT = safariZoneTile({
  frontSrc: hideoutSrc,
  connections: {
    up: TileConnectionPath.TREES,
    down: TileConnectionPath.SANDY_PATH,
    left: TileConnectionPath.TREES,
    right: TileConnectionPath.TREES,
  },
});

export const WISHING_WELL = safariZoneTile({
  frontSrc: wellSrc,
  connections: {
    up: TileConnectionPath.SANDY_PATH,
    down: TileConnectionPath.SANDY_PATH,
    left: TileConnectionPath.TREES,
    right: TileConnectionPath.SANDY_PATH,
  },
});
