import { createSelector } from "reselect";
import { SAFARI_ZONE_TILES, TOWN_TILES } from "../../tiles/data";
import {
  Direction,
  isTownTile,
  TileConnectionPath,
} from "../../tiles/tiles.types";
import {
  Coordinate,
  GameState,
  PlacedTile,
  PlacedTileMap,
  PlayerMovement,
  PlayerRawStats,
  ProposedTilePlacement,
  TurnStage,
  XYCoordinateString,
} from "../game.types";
import { rotateHinges } from "../utils/isConnectedByPath";
import isPlacementLegal from "../utils/isPlacementLegal";
import getPossibleMovements from "../utils/getPossibleMovements";
import {
  EncounterHour,
  IEncounterCard,
} from "../../encounter/types/encounter.types";
import { Item, ItemType } from "../../encounter/types/item.types";
import { ENCOUNTER_CARDS } from "../../encounter/data";
import {
  IPokemon,
  PokemonSpecies,
  PokemonVariant,
} from "../../pokemon/pokemon.types";
import { POKEMON } from "../../pokemon/data";

export const selectGameHoursLeft = (game: GameState): EncounterHour =>
  game.hoursLeft;

const selectPlacedTilesMap = (game: GameState): PlacedTileMap =>
  game.placedTiles;

export const selectPlayerCoordinates = (game: GameState): Coordinate =>
  game.player.coordinates;

export const selectPlayerItemsRecord = (
  game: GameState
): Partial<Record<ItemType, boolean>> => game.player.items;

export const selectPlayerItemsIds = createSelector(
  selectPlayerItemsRecord,
  (items): ItemType[] =>
    (Object.entries(items) as [ItemType, boolean][])
      .filter(([_, isPresent]) => isPresent)
      .map(([itemType]) => itemType)
);

export const selectPlayerItemsList = createSelector(
  selectPlayerItemsIds,
  (itemIds): Item[] =>
    (
      itemIds
        .map((itemId) =>
          Object.values(ENCOUNTER_CARDS).find(
            (card) => card.item.type === itemId
          )
        )
        .filter((val) => !!val) as IEncounterCard[]
    ).map((card) => card.item)
);

const selectLastMovement = (game: GameState): PlayerMovement | undefined =>
  game.lastMovement;

const selectUnexploredPathCount = (game: GameState): number =>
  game.unexploredPaths;

const selectTurnStage = (game: GameState): TurnStage => game.turnStage;

export const selectActiveDrawnEncounterCard = (
  game: GameState
): IEncounterCard | undefined => game.encounterCards.active;

const selectEncounterCardsDeck = (game: GameState): IEncounterCard[] =>
  game.encounterCards.deck;

export const selectRemainingEncounterCardsCount = createSelector(
  selectEncounterCardsDeck,
  (deck) => deck.length
);

export const selectItemDiscoveryCard = (
  game: GameState
): IEncounterCard | undefined => game.encounterCards.itemDiscovery;

export const selectActiveEncounterOrItemCard = createSelector(
  selectActiveDrawnEncounterCard,
  selectItemDiscoveryCard,
  (drawnEncounter, itemDiscovery) => drawnEncounter ?? itemDiscovery
);

const selectPlayerRawStats = (game: GameState): PlayerRawStats =>
  game.player.stats;

const selectPlayerRawAttack = createSelector(
  selectPlayerRawStats,
  (stats) => stats.attack
);

const selectPlayerRawHealth = createSelector(
  selectPlayerRawStats,
  (stats) => stats.hp
);

export const selectActiveWildPokemon = (
  game: GameState
): IPokemon | undefined => game.wildPokemon.active;

export const selectPartyPokemon = (
  game: GameState
): (IPokemon & {
  chosenVariant?: PokemonVariant;
})[] => {
  const pokemonEntries = (
    Object.entries(game.player.pokemon) as [
      PokemonSpecies,
      boolean | PokemonVariant
    ][]
  ).filter(([_, presence]) => !!presence);
  const partyPokemonSpecies = pokemonEntries
    .map(([species, chosenVariant]) => ({
      ...Object.values(POKEMON).find((pokemon) => pokemon.species === species),
      chosenVariant:
        typeof chosenVariant === "string" ? chosenVariant : undefined,
    }))
    .filter((val) => !!val) as (IPokemon & {
    chosenVariant?: PokemonVariant;
  })[];

  return partyPokemonSpecies;
};

export const selectPlayerItemsAttackBonus = createSelector(
  selectPlayerItemsList,
  (items) =>
    items.reduce((acc, val) => {
      switch (val.type) {
        case ItemType.HM01:
        case ItemType.MAGNET:
          return acc + 1;

        default:
          return acc;
      }
    }, 0)
);

export const selectPlayerPokemonAttackBonus = createSelector(
  selectPartyPokemon,
  (pokemon) =>
    pokemon.reduce((acc, val) => {
      switch (val.species) {
        case PokemonSpecies.ELECTRODE:
        case PokemonSpecies.LAPRAS:
          return acc + 1;

        case PokemonSpecies.KANGASKHAN:
          return acc + 2;

        case PokemonSpecies.ABRA:
          return val.chosenVariant === PokemonVariant.ABRA_TELEPORT
            ? acc + 1
            : acc;

        case PokemonSpecies.CHARIZARD:
          if (val.chosenVariant === PokemonVariant.CHARIZARD_FIRE_BLAST) {
            return acc + 3;
          } else if (val.chosenVariant === PokemonVariant.CHARIZARD_FLY) {
            return acc + 1;
          } else {
            return acc;
          }

        default:
          return acc;
      }
    }, 0)
);

export const selectPlayerPokemonHealthBonus = createSelector(
  selectPartyPokemon,
  (pokemon) =>
    pokemon.reduce((acc, val) => {
      switch (val.species) {
        case PokemonSpecies.KANGASKHAN:
          return acc + 3;

        default:
          return acc;
      }
    }, 0)
);

export const selectPlayerComputedHealth = createSelector(
  selectPlayerRawHealth,
  selectPlayerPokemonHealthBonus,
  (health, pokemonBonus) => health + pokemonBonus
);

export const selectPlayerComputedAttack = createSelector(
  selectPlayerRawAttack,
  selectPlayerItemsAttackBonus,
  selectPlayerPokemonAttackBonus,
  (attack, itemsBonus, pokemonBonus) => attack + itemsBonus + pokemonBonus
);

export const selectGetTileAtCoordinate = createSelector(
  selectPlacedTilesMap,
  (placedTiles) =>
    ({ x, y }: Coordinate) =>
      placedTiles[`${x},${y}`] as PlacedTile | undefined
);

const selectPlayerCoordinateString = createSelector(
  selectPlayerCoordinates,
  ({ x, y }): XYCoordinateString => `${x},${y}`
);

export const selectCurrentPlayerTile = createSelector(
  selectPlacedTilesMap,
  selectPlayerCoordinateString,
  (placedTiles, coordStr) => placedTiles[coordStr]
);

export const selectCurrentPlayerTileHinges = createSelector(
  selectCurrentPlayerTile,
  (tile) => rotateHinges(tile.data.connections, tile.state?.rotate ?? 0)
);

export const selectPossiblePlayerMovements = createSelector(
  selectPlacedTilesMap,
  selectPlayerCoordinates,
  selectTurnStage,
  (placedTiles, coordinates, turnStage): PlayerMovement[] | undefined => {
    if (turnStage === TurnStage.MOVEMENT_CHOICE) {
      return getPossibleMovements(placedTiles, coordinates, {
        newPaths: true,
        existingPaths: true,
      });
    } else if (turnStage === TurnStage.OPPONENT_FLEE) {
      return getPossibleMovements(placedTiles, coordinates, {
        newPaths: false,
        existingPaths: true,
      });
    } else {
      return undefined;
    }
  }
);

export const selectCurrentPlayerTileRocketRoutes = createSelector(
  selectCurrentPlayerTileHinges,
  (connectionMap): Record<Direction, boolean> => {
    return Object.keys(connectionMap).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: [
          TileConnectionPath.CUTTABLE_HEDGE,
          TileConnectionPath.FENCING,
          TileConnectionPath.TREES,
        ].includes(connectionMap[curr as Direction]),
      }),
      {} as Record<Direction, boolean>
    );
  }
);

const selectProposedPlacement = (
  game: GameState
): ProposedTilePlacement | undefined => game.placement;

const selectProposedPlacementTileData = createSelector(
  selectProposedPlacement,
  (placement) => placement?.data
);

const selectProposedPlacementTileState = createSelector(
  selectProposedPlacement,
  (placement) => placement?.state
);

const selectProposedPlacementCoordinates = createSelector(
  selectProposedPlacement,
  (placement) => placement?.to
);

export const selectHasLegalPlacement = createSelector(
  selectPlacedTilesMap,
  selectProposedPlacementTileData,
  selectProposedPlacementTileState,
  selectProposedPlacementCoordinates,
  selectPlayerCoordinates,
  (placedTiles, tileData, tileState, proposedCoordinates, playerCoordinates) =>
    tileData &&
    proposedCoordinates &&
    isPlacementLegal(placedTiles, {
      data: tileData,
      state: tileState,
      to: proposedCoordinates,
      from: playerCoordinates,
    })
);

export const selectHasPlacedAllNecessaryTownTiles = createSelector(
  selectPlacedTilesMap,
  (placedTiles) => {
    const tileList = Object.values(placedTiles);
    return [TOWN_TILES.HOME, TOWN_TILES.OAKS_LAB, TOWN_TILES.SAFARI_ZONE].every(
      ({ id }) => tileList.some(({ data }) => id === data.id)
    );
  }
);

export const selectHasPlacedAllNecessarySafariZoneTiles = createSelector(
  selectPlacedTilesMap,
  (placedTiles) => {
    const tileList = Object.values(placedTiles);
    return [SAFARI_ZONE_TILES.ENTRANCE, SAFARI_ZONE_TILES.ROCKET_HIDEOUT].every(
      ({ id }) => tileList.some(({ data }) => id === data.id)
    );
  }
);

export const selectHasPlacedAllNecessaryTiles = createSelector(
  selectHasPlacedAllNecessarySafariZoneTiles,
  selectHasPlacedAllNecessaryTownTiles,
  (hasPlacedAllNecessarySafariZoneTiles, hasPlacedAllNecessaryTownTiles) =>
    hasPlacedAllNecessaryTownTiles && hasPlacedAllNecessarySafariZoneTiles
);

export const selectIsRocketAttackDue = createSelector(
  selectCurrentPlayerTile,
  selectLastMovement,
  selectUnexploredPathCount,
  selectHasPlacedAllNecessaryTownTiles,
  selectHasPlacedAllNecessarySafariZoneTiles,
  (
    currentTile,
    lastMovement,
    unexploredPathCount,
    allNeededTownTiles,
    allNeededSafariZoneTiles
  ) => {
    if (unexploredPathCount === 0) {
      return isTownTile(currentTile.data)
        ? !allNeededTownTiles
        : !allNeededSafariZoneTiles;
    } else {
      return false;
    }
  }
);
