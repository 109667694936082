import BagModal from "../../bag/components/BagModal";
import {
  EncounterHour,
  IEncounterCard,
} from "../../encounter/types/encounter.types";
import { Item } from "../../encounter/types/item.types";
import PokemonCarouselModal from "../../pokemon/components/PokemonCarouselModal";
import PokemonModal from "../../pokemon/components/PokemonModal";
import { IPokemon } from "../../pokemon/pokemon.types";
import { TurnStage } from "../game.types";
import EncounterModal from "./EncounterModal";
import { GameHandlers } from "./GameOngoing";

interface Props {
  encounterCard?: IEncounterCard;
  handlers: GameHandlers;
  hoursLeft: EncounterHour;
  itemDiscoveryCard?: IEncounterCard;
  onCloseBag?(): void;
  onCloseEncounter?(): void;
  onCloseItemDiscovery?(): void;
  onClosePartyPokemon?(): void;
  onCloseWildPokemon?(): void;
  onPartyPokemonSelect?(index: number): void;
  open?: Partial<
    Record<"bag" | "encounter" | "item" | "wild", boolean> & { party?: number }
  >;
  partyPokemonList: IPokemon[];
  playerItems: Item[];
  turnStage?: TurnStage;
  wildPokemon?: IPokemon;
}

function GameModals({
  encounterCard,
  handlers,
  hoursLeft,
  itemDiscoveryCard,
  onCloseBag,
  onCloseEncounter,
  onCloseItemDiscovery,
  onClosePartyPokemon,
  onCloseWildPokemon,
  onPartyPokemonSelect,
  open,
  partyPokemonList,
  playerItems,
  turnStage,
  wildPokemon,
}: Props): JSX.Element {
  return (
    <>
      <EncounterModal
        card={encounterCard}
        handlers={handlers}
        active={hoursLeft}
        isOpen={turnStage === TurnStage.ENCOUNTER_CARD && open?.encounter}
        onClose={onCloseEncounter}
      />
      <EncounterModal
        card={itemDiscoveryCard}
        handlers={handlers}
        active="item"
        isOpen={turnStage === TurnStage.ITEM_DISCOVERY && open?.item}
        title="Item discovery"
        onClose={onCloseItemDiscovery}
      />
      <BagModal
        handlers={handlers}
        isOpen={open?.bag}
        items={playerItems}
        onClose={onCloseBag}
      />
      <PokemonModal
        handlers={handlers}
        isCatchable={turnStage === TurnStage.WILD_POKEMON}
        isOpen={open?.wild}
        pokemon={wildPokemon}
        onClose={onCloseWildPokemon}
      />
      <PokemonCarouselModal
        handlers={handlers}
        index={open?.party}
        isOpen={typeof open?.party === "number"}
        onIndexChange={(n) => onPartyPokemonSelect && onPartyPokemonSelect(n)}
        onClose={onClosePartyPokemon}
        pokemonList={partyPokemonList}
      />
    </>
  );
}

export default GameModals;
