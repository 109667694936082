import ActiveTiles from "../../tiles/components/ActiveTiles";
import { Direction } from "../../tiles/tiles.types";
import {
  Coordinate,
  PlacedTileMap,
  PlayerMovement,
  ProposedTilePlacement,
  TurnStage,
} from "../game.types";
import { Axis, GameHandlers } from "./GameOngoing";
import RocketAttack from "./RocketAttack";

interface Props {
  isPlacementLegal?: boolean;
  handlers: GameHandlers;
  placedTiles: PlacedTileMap;
  placement?: ProposedTilePlacement;
  playerCoordinates: Coordinate;
  possibleMovements?: PlayerMovement[];
  possibleRocketAttack: Record<Direction, boolean>;
  renderOnPlayerTile?: React.FC;
  tileSize: React.CSSProperties["width"];
  turnStage: TurnStage;
  xAxis: Axis;
  yAxis: Axis;
}

function BoardTiles({
  handlers,
  isPlacementLegal,
  placedTiles,
  placement,
  playerCoordinates,
  possibleMovements,
  possibleRocketAttack,
  renderOnPlayerTile,
  tileSize,
  turnStage,
  xAxis,
  yAxis,
}: Props): JSX.Element {
  return (
    <ActiveTiles
      style={{ padding: "50px" }}
      {...{
        isPlacementLegal,
        handlers,
        placedTiles,
        placement,
        playerCoordinates,
        renderOnPlayerTile,
        tileSize,
        xAxis,
        yAxis,
      }}
      possibleMovements={
        turnStage === TurnStage.MOVEMENT_CHOICE ? possibleMovements : undefined
      }
    >
      {({ gridAdjustX, gridAdjustY }) => {
        if (turnStage === TurnStage.ROCKET_ATTACK_ROUTE_CHOICE) {
          return (
            <RocketAttack
              {...{
                gridAdjustX,
                gridAdjustY,
                onRocketAmbushTrigger: handlers.onRocketAmbushTrigger,
                playerCoordinates,
                possibleRocketAttack,
              }}
            />
          );
        }

        return null;
      }}
    </ActiveTiles>
  );
}

export default BoardTiles;
