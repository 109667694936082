import { Item } from "./item.types";

export enum EncounterType {
  GENERAL_EVENT = "general event",
  ITEM_FOUND = "item found",
  ROCKET_BATTLE = "Rocket battle",
  WILD_POKEMON = "wild Pokémon",
}

export type EncounterHour = 1 | 2 | 3;

interface EncounterBase {
  type: EncounterType;
  hoursLeft: EncounterHour;
  text?: string;
}

export type Encounter =
  | BattleEncounter
  | EventEncounter
  | ItemEncounter
  | PokemonEncounter;

export interface BattleEncounter extends EncounterBase {
  type: EncounterType.ROCKET_BATTLE;
  enemyPokemon: number;
}

export function isBattleEncounter(e: Encounter): e is BattleEncounter {
  return e.type === EncounterType.ROCKET_BATTLE;
}

export interface EventEncounter extends EncounterBase {
  type: EncounterType.GENERAL_EVENT;
  text: string;
  increment: Partial<Record<"hp" | "attack", number>>;
}

export function isEventEncounter(e: Encounter): e is EventEncounter {
  return e.type === EncounterType.GENERAL_EVENT;
}

export interface ItemEncounter extends EncounterBase {
  type: EncounterType.ITEM_FOUND;
}

export function isItemEncounter(e: Encounter): e is ItemEncounter {
  return e.type === EncounterType.ITEM_FOUND;
}

export interface PokemonEncounter extends EncounterBase {
  type: EncounterType.WILD_POKEMON;
}

export function isPokemonEncounter(e: Encounter): e is PokemonEncounter {
  return e.type === EncounterType.WILD_POKEMON;
}

export interface IEncounterCard {
  id: string;
  tMinus: Record<EncounterHour, Encounter>;
  item: Item;
}
