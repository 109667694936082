import styled from "styled-components";
import openSrc from "../assets/bag-open.png";
import closedSrc from "../assets/bag-closed.png";

interface Props {
  className?: string;
  children?: React.ReactNode;
  onClick?(): void;
  style?: React.CSSProperties;
  variant?: "open" | "closed";
  bagWidth?: React.CSSProperties["width"];
}

const Container = styled.div`
  display: grid;
  grid-template-areas: "bag-content";

  img {
    grid-area: bag-content;
  }

  .bag-children {
    grid-area: bag-content;
    height: 100%;
    width: 100%;
  }
`;

function Bag({
  className,
  style,
  children,
  onClick,
  variant = "open",
  bagWidth,
}: Props): JSX.Element {
  return (
    <Container {...{ className, onClick, style }}>
      <img
        alt="bag"
        src={variant === "open" ? openSrc : closedSrc}
        style={{ width: bagWidth }}
      />
      <div className="bag-children">{children}</div>
    </Container>
  );
}

export default Bag;
