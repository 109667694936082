import { IPokemon } from "../pokemon.types";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  onClick?(): void;
  pokemon: IPokemon;
  img?: "png" | "gif" | "box";
}

function PokemonSprite({
  className,
  style,
  onClick,
  pokemon,
  img = "gif",
}: Props): JSX.Element {
  return (
    <img
      {...{ className, style, onClick }}
      alt={pokemon.species}
      src={pokemon.src[img]}
    />
  );
}

export default PokemonSprite;
