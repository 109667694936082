import { TOWN_TILES } from "../../tiles/data";
import { Direction, TileConnectionPath } from "../../tiles/tiles.types";
import {
  Coordinate,
  PlacedTileMap,
  ProposedTilePlacement,
  XYCoordinateString,
} from "../game.types";
import { translate } from "./direction-utils";
import isConnectedByPath, { rotateHinges } from "./isConnectedByPath";

function isPlacementLegal(
  placedTiles: PlacedTileMap,
  { data, state, to, from }: ProposedTilePlacement & { from: Coordinate }
): boolean {
  const connectingHinge = getPlacementConnectingHinge({ to, from });
  const sourceCoordinate: XYCoordinateString = `${from.x},${from.y}`;
  const sourceTile = placedTiles[sourceCoordinate];

  if (!sourceTile) {
    throw new Error("No source tile found");
  }

  // special case of Safari Zone - can't close it off
  if (data.id === TOWN_TILES.SAFARI_ZONE.id) {
    const rotatedConnections = rotateHinges(
      data.connections,
      state?.rotate ?? 0
    );
    let isSafariEntranceUnblocked = true;

    for (const [direction, connection] of Object.entries(
      rotatedConnections
    ) as [Direction, TileConnectionPath][]) {
      if (connection === TileConnectionPath.ENTER_SAFARI_ZONE) {
        const connectingCoords = translate(to, direction);
        const coordStr: XYCoordinateString = `${connectingCoords.x},${connectingCoords.y}`;
        isSafariEntranceUnblocked = !placedTiles[coordStr];
      }
    }
    return (
      isSafariEntranceUnblocked &&
      isConnectedByPath({ data, state }, connectingHinge, sourceTile)
    );
  } else {
    return isConnectedByPath({ data, state }, connectingHinge, sourceTile);
  }
}

const getPlacementConnectingHinge = ({
  to,
  from,
}: Record<"to" | "from", Coordinate>): Direction => {
  if (to.x === from.x && to.y === from.y + 1) {
    return Direction.DOWN;
  } else if (to.x === from.x && to.y === from.y - 1) {
    return Direction.UP;
  } else if (to.x === from.x + 1 && to.y === from.y) {
    return Direction.LEFT;
  } else if (to.x === from.x - 1 && to.y === from.y) {
    return Direction.RIGHT;
  } else {
    throw new Error("These two don't connect");
  }
};

export default isPlacementLegal;
