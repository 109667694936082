import { Game } from "boardgame.io";
import { ENCOUNTER_CARDS } from "../encounter/data";
import { SAFARI_ZONE_TILES, TOWN_TILES } from "../tiles/data";
import {
  TurnStage,
  GameState,
  INITIAL_TILE_MAP,
  GAME_MOVES,
} from "./game.types";

const TeamRocketInMyPocket: Game<GameState> = {
  setup: (ctx) => {
    // hopefully okay to assert - might be a library bug?
    // should be there: https://boardgame.io/documentation/#/random?id=api-reference
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const randomAPI = ctx.random!;

    const [discardedCard, ...encounterDeck] = randomAPI.Shuffle(
      Object.values(ENCOUNTER_CARDS)
    );

    return {
      encounterCards: {
        deck: encounterDeck,
        discard: [discardedCard],
      },
      hoursLeft: 3,
      placedTiles: INITIAL_TILE_MAP,
      player: {
        coordinates: { x: 0, y: 0 },
        items: {},
        pokemon: {
          Pikachu: true,
        },
        stats: {
          hp: 6,
          attack: 1,
        },
      },
      tileStacks: {
        town: randomAPI.Shuffle(
          Object.values(TOWN_TILES).filter(
            ({ id }) => id !== TOWN_TILES.HOME.id
          )
        ),
        safariZone: Object.values(SAFARI_ZONE_TILES).filter(
          ({ id }) => id !== SAFARI_ZONE_TILES.ENTRANCE.id
        ),
      },
      turnStage: TurnStage.MOVEMENT_CHOICE,
      unexploredPaths: 1,
      wildPokemon: {},
    };
  },

  moves: GAME_MOVES,
};

export default TeamRocketInMyPocket;
