import styled from "styled-components";
import { IPokemon } from "../pokemon.types";
import PokemonSprite from "./PokemonSprite";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  onPokemonClick?(pokemon: IPokemon, index: number): void;
  pokemonList: IPokemon[];
}

const Container = styled.div``;

function PartyPokemon({
  className,
  style,
  onPokemonClick,
  pokemonList,
}: Props): JSX.Element {
  return (
    <Container {...{ className, style }}>
      {pokemonList.map((pokemon, index) => (
        <PokemonSprite
          key={pokemon.species}
          {...{ pokemon }}
          img="box"
          onClick={() => onPokemonClick && onPokemonClick(pokemon, index)}
        />
      ))}
    </Container>
  );
}

export default PartyPokemon;
