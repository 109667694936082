import { Button, Modal, Space } from "@mantine/core";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import pokedexSrc from "../assets/pokedex.jpg";
import { GameHandlers } from "../../game/components/GameOngoing";
import { IPokemon } from "../pokemon.types";
import Pokeball from "../../bag/components/Pokeball";
import { animated, useSprings } from "react-spring";
import PokemonCard from "./PokemonCard";

interface Props {
  handlers: GameHandlers;
  index?: number;
  isCatchable?: boolean;
  isOpen?: boolean;
  onClose?(): void;
  onIndexChange?(n: number): void;
  pokemonList: IPokemon[];
  title?: string;
}

const StyledCarousel = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 5%;
  height: 50vh;
  y-overflow: scroll;

  display: grid;
  grid-template-areas: "carousel-item";

  .carousel-item {
    grid-area: carousel-item;
  }
`;

const CarouselItem = styled(animated.div)`
  grid-area: carousel-item;
`;

const CarouselDots = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  column-gap: 5px;
`;

const CarouselDot = styled(Pokeball)`
  height: 24px;
  border-radius: 50%;
`;

const StyledPokeball = styled(Pokeball)`
  height: 20px;
`;

function PokemonCarouselModal({
  handlers,
  index: activeIndex = 0,
  isCatchable,
  isOpen,
  onClose,
  onIndexChange,
  pokemonList,
  title = "Pokédex",
}: Props): JSX.Element {
  const springs = useSprings(
    pokemonList.length,
    pokemonList.map((pokemon, idx) => ({
      cardOpacity: idx === activeIndex ? 1 : 0,
      dotBackgroundColor: `rgba(255, 255, 255, ${idx === activeIndex ? 1 : 0})`,
      dotFilter: `grayscale(${idx === activeIndex ? 0 : 100}%)`,
    }))
  );

  const vh100 = use100vh() ?? "calc(var(--vh, vh) * 100)";

  return (
    <Modal
      opened={!!isOpen}
      onClose={() => onClose && onClose()}
      styles={{
        modal: {
          backgroundImage: `url("${pokedexSrc}")`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          height: `calc(${vh100} * 0.8)`,
          width: `calc(${vh100} * 0.8 * 0.562)`,
        },
      }}
      // title={<Title order={3}>{title}</Title>}
    >
      <StyledCarousel>
        {pokemonList.map((pokemon, idx) => (
          <CarouselItem key={idx} style={{ opacity: springs[idx].cardOpacity }}>
            <PokemonCard className="carousel-item" {...{ pokemon }} />
          </CarouselItem>
        ))}
      </StyledCarousel>
      <CarouselDots>
        {pokemonList.map((pokemon, idx) => (
          <CarouselDot
            key={idx}
            style={{
              backgroundColor: springs[idx].dotBackgroundColor,
              filter: springs[idx].dotFilter,
            }}
            onClick={() => onIndexChange && onIndexChange(idx)}
          />
        ))}
      </CarouselDots>
      <Space />
      <Button
        compact
        disabled
        fullWidth
        variant="gradient"
        gradient={{ from: "red", to: "green" }}
      >
        <StyledPokeball /> Already owned <StyledPokeball />
      </Button>
      <Button
        compact
        fullWidth
        variant="light"
        onClick={() => onClose && onClose()}
      >
        Back to map
      </Button>
    </Modal>
  );
}

export default PokemonCarouselModal;
