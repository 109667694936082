import hm01Src from "../../bag/assets/items/hm-normal.png";
import hyperPotionSrc from "../../bag/assets/items/hyper-potion.png";
import choiceBandSrc from "../../bag/assets/items/choice-band.png";
import magnetSrc from "../../bag/assets/items/magnet.png";
import pokeballSrc from "../../bag/assets/items/pokeball.png";
import pokefluteSrc from "../../bag/assets/items/pokeflute.png";
import pokeradarSrc from "../../bag/assets/items/pokeradar.png";
import rocketUniformSrc from "../../bag/assets/items/rocket-uniform.png";
import sitrusBerrySrc from "../../bag/assets/items/sitrus-berry.png";
import { IEncounterCard, EncounterType } from "../types/encounter.types";
import { ItemPersistence, ItemType } from "../types/item.types";

export const CHOICE_BAND_CARD: IEncounterCard = {
  id: ItemType.CHOICE_BAND,
  tMinus: {
    3: {
      type: EncounterType.WILD_POKEMON,
      hoursLeft: 3,
    },
    2: {
      type: EncounterType.ITEM_FOUND,
      hoursLeft: 2,
    },
    1: {
      type: EncounterType.ROCKET_BATTLE,
      hoursLeft: 1,
      enemyPokemon: 6,
    },
  },
  item: {
    type: ItemType.CHOICE_BAND,
    imageSrc: choiceBandSrc,
    descriptionHeader: "+2 ATK",
    descriptionBody:
      "greatly increases your Pokémon's power for a battle, but is inflexible and can only be used once.",
    persistence: ItemPersistence.SINGLE_BATTLE,
  },
};

export const HM01_CARD: IEncounterCard = {
  id: ItemType.HM01,
  tMinus: {
    3: {
      type: EncounterType.ITEM_FOUND,
      hoursLeft: 3,
    },
    2: {
      type: EncounterType.ROCKET_BATTLE,
      hoursLeft: 2,
      enemyPokemon: 5,
    },
    1: {
      type: EncounterType.GENERAL_EVENT,
      hoursLeft: 1,
      text: "Pikachu is caught in a trap set by Team Rocket! After a lot of effort, it manages to break free.",
      increment: { hp: -1 },
    },
  },
  item: {
    type: ItemType.HM01,
    imageSrc: hm01Src,
    descriptionHeader: "+1 ATK",
    descriptionBody:
      "an attack with sharp claws that can also cut down funny-looking trees to open up paths.",
    persistence: ItemPersistence.WHILST_HELD,
  },
};

export const HYPER_POTION_CARD: IEncounterCard = {
  id: ItemType.HYPER_POTION,
  tMinus: {
    3: {
      type: EncounterType.ROCKET_BATTLE,
      hoursLeft: 3,
      enemyPokemon: 4,
    },
    2: {
      type: EncounterType.GENERAL_EVENT,
      hoursLeft: 2,
      text: "As you walk under a nearby tree, a wild Spearow swoops out and pecks Pikachu.",
      increment: { hp: -1 },
    },
    1: {
      type: EncounterType.ROCKET_BATTLE,
      hoursLeft: 1,
      enemyPokemon: 6,
    },
  },
  item: {
    type: ItemType.HYPER_POTION,
    imageSrc: hyperPotionSrc,
    descriptionHeader: "+2 HP",
    descriptionBody: "use on a party Pokémon to restore HP.",
    persistence: ItemPersistence.SINGLE_USE,
  },
};

export const MAGNET_CARD: IEncounterCard = {
  id: ItemType.MAGNET,
  tMinus: {
    3: {
      type: EncounterType.ITEM_FOUND,
      hoursLeft: 3,
    },
    2: {
      type: EncounterType.ROCKET_BATTLE,
      hoursLeft: 2,
      enemyPokemon: 4,
    },
    1: {
      type: EncounterType.GENERAL_EVENT,
      hoursLeft: 1,
      text: "Rain starts falling. Pikachu doesn't like the wet weather!",
      increment: { hp: -1 },
    },
  },
  item: {
    type: ItemType.MAGNET,
    imageSrc: magnetSrc,
    descriptionHeader: "+1 ATK",
    descriptionBody:
      "a held item that boosts the power of your Pikachu's Electric-type attacks.",
    persistence: ItemPersistence.WHILST_HELD,
  },
};

export const POKEBALL_CARD: IEncounterCard = {
  id: ItemType.POKEBALL,
  tMinus: {
    3: {
      type: EncounterType.ROCKET_BATTLE,
      hoursLeft: 3,
      enemyPokemon: 4,
    },
    2: {
      type: EncounterType.GENERAL_EVENT,
      hoursLeft: 2,
      text: "Pikachu races ahead of you, but trips on a loose rock and falls over.",
      increment: {
        hp: -1,
      },
    },
    1: {
      type: EncounterType.ITEM_FOUND,
      hoursLeft: 1,
    },
  },
  item: {
    type: ItemType.POKEBALL,
    imageSrc: pokeballSrc,
    descriptionBody:
      "Draw one Pokémon card, and add it to your party. Your maximum party size increases by one (Pikachu + 2 others).",
    persistence: ItemPersistence.WHILST_HELD,
  },
};

export const POKERADAR_CARD: IEncounterCard = {
  id: ItemType.POKERADAR,
  tMinus: {
    3: {
      type: EncounterType.GENERAL_EVENT,
      hoursLeft: 3,
      text: "Pikachu found a berry bush on the side of the road - but feels a bit sick after eating some.",
      increment: { hp: -1 },
    },
    2: {
      type: EncounterType.ROCKET_BATTLE,
      hoursLeft: 2,
      enemyPokemon: 4,
    },
    1: {
      type: EncounterType.WILD_POKEMON,
      hoursLeft: 1,
    },
  },
  item: {
    type: ItemType.POKERADAR,
    imageSrc: pokeradarSrc,
    descriptionBody:
      "Draw two Pokémon cards, and pick up to one of them to add into your party.",
    persistence: ItemPersistence.SINGLE_USE,
  },
};

export const POKEFLUTE_CARD: IEncounterCard = {
  id: ItemType.POKEFLUTE,
  tMinus: {
    3: {
      type: EncounterType.ROCKET_BATTLE,
      hoursLeft: 3,
      enemyPokemon: 3,
    },
    2: {
      type: EncounterType.WILD_POKEMON,
      hoursLeft: 2,
    },
    1: {
      type: EncounterType.ROCKET_BATTLE,
      hoursLeft: 1,
      enemyPokemon: 5,
    },
  },
  item: {
    type: ItemType.POKEFLUTE,
    imageSrc: pokefluteSrc,
    descriptionBody:
      "Put an enemy trainer and their party Pokémon to sleep, then run back to the location you came from, avoiding damage.",
    persistence: ItemPersistence.SINGLE_BATTLE,
  },
};

export const SITRUS_BERRY_CARD: IEncounterCard = {
  id: ItemType.SITRUS_BERRY,
  tMinus: {
    3: {
      type: EncounterType.GENERAL_EVENT,
      hoursLeft: 3,
      text: "You find a Potion in the bottom of your bag and use it on Pikachu.",
      increment: { hp: 1 },
    },
    2: {
      type: EncounterType.ITEM_FOUND,
      hoursLeft: 2,
    },
    1: {
      type: EncounterType.ROCKET_BATTLE,
      hoursLeft: 1,
      enemyPokemon: 4,
    },
  },
  item: {
    type: ItemType.SITRUS_BERRY,
    imageSrc: sitrusBerrySrc,
    descriptionHeader: "+1 HP",
    descriptionBody: "use on a party Pokémon to restore HP.",
    persistence: ItemPersistence.SINGLE_USE,
  },
};

export const ROCKET_UNIFORM_CARD: IEncounterCard = {
  id: ItemType.ROCKET_UNIFORM,
  tMinus: {
    3: {
      type: EncounterType.WILD_POKEMON,
      hoursLeft: 3,
    },
    2: {
      type: EncounterType.GENERAL_EVENT,
      hoursLeft: 2,
      text: "You find a Rare Candy in your pocket and give it to Pikachu.",
      increment: { attack: 1, hp: 1 },
    },
    1: {
      type: EncounterType.ROCKET_BATTLE,
      hoursLeft: 1,
      enemyPokemon: 4,
    },
  },
  item: {
    type: ItemType.ROCKET_UNIFORM,
    imageSrc: rocketUniformSrc,
    descriptionBody:
      "Trick an enemy Rocket into skipping battle, avoiding all damage.",
    persistence: ItemPersistence.SINGLE_BATTLE,
  },
};
